import React, { useEffect, useState } from 'react';
import {
  createStyles,
  Table,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  Button,
  Pagination,
  Container,
} from '@mantine/core';
import { keys } from '@mantine/utils';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons';
import { SquarePlus } from 'tabler-icons-react';
import { observer } from 'mobx-react';

const useStyles = createStyles((theme) => ({
  th: {
    padding: '0 !important',
    // background: theme.colorScheme == 'dark' ? theme.colors.dark : 'white'
  },
  itemHover: {
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    }
  },
  itemTextHover: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    }
  },
  control: {
    width: '100%',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },
  td: {
    width: '1px',
    whiteSpace: 'nowrap',
    //backgroundColor: 'blue'
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));
interface RowData {
  [key: string]: any | undefined;
}

interface TableSortProps {
  count?: number,
  getData?: any,
  componentButton?: any,
  width?: number,
  postsPerPage?: number;
  data: RowData[];
  handleClickNew?: (id: null) => void;
  handleClickItem: (id: any) => void;
  newButtonText?: string;
  tableLayout?: any;
  changeSearchBar?: any;
  initialSortBy?: string;
  disableButton?: boolean;
  changeNewButton?: boolean;
  reverseInitialSortOrder?: boolean;
}

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
  sortable: boolean;
}

function Th({ children, reversed, sorted, onSort, sortable }: ThProps) {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <th className={classes.th}>
      {sortable ? (<UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <Text weight={500} size="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={14} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>) : (

        <Text weight={500} size="sm">
          {children}
        </Text>)}
    </th>);
}

function filterData(data: RowData[], search: string) {
  const query = search.toLowerCase().trim();
  return data.filter((item) => {
    return keys(data[0]).some((key) => {
      let returnValue = typeof item[key] === 'object' ? String(item[key]?.value) : item[key];//if value is an object, 
      return returnValue.toLowerCase().includes(query)
    })
  });
}


function sortData(data: RowData[], payload: { sortBy: keyof RowData; reversed: boolean; search: string }) {
  const { sortBy } = payload;
  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return doCompare(b, a, sortBy);
      }
      return doCompare(a, b, sortBy);
    }),
    payload.search
  );
}

function doCompare(first: any, second: any, sortBy: keyof RowData) {
  if (typeof first[sortBy] === 'object') {
    switch (typeof first[sortBy].value) {
      case 'number':
        return first[sortBy].value - second[sortBy].value;
      case 'boolean':
        return (first[sortBy].value === second[sortBy].value) ? 0 : first[sortBy].value ? -1 : 1;
      default:
        return String(first[sortBy].value).localeCompare(String(second[sortBy].value))
    }
  }
  else return first[sortBy]?.localeCompare(second[sortBy]);
}

export const TableSort = observer(({ count, getData, componentButton, width, postsPerPage, data, handleClickNew, handleClickItem, newButtonText, tableLayout,
  disableButton, changeSearchBar, initialSortBy, reverseInitialSortOrder }: TableSortProps) => {
  const { classes } = useStyles();
  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState<RowData[]>(data?.length > 0 ? data : []);
  const [sortBy, setSortBy] = useState<keyof RowData>(initialSortBy ? initialSortBy : data?.length > 0 ? Object.keys(data[0])[1] : '');
  const [reverseSortDirection, setReverseSortDirection] = useState(reverseInitialSortOrder ? true : false);
  const [activePage, setActivePage] = useState<number>(1);

  postsPerPage = postsPerPage ? postsPerPage : 8;
  const indexOfLastPost = activePage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  useEffect(() => {
    data?.length > 0 && setSortedData(sortData(data, { sortBy: sortBy, reversed: reverseSortDirection, search: search }));
  }, [data])

  useEffect(() => {
    setActivePage(1);
  }, [search, sortBy])

  const setSorting = (field: string) => {
    setActivePage(1);
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search }));
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search: value }));
  };

  const header = (<tr key={'headerstuff'}>
    {
      data?.length > 0 && Object.entries(data[0]).map(([key, val], i) => {
        if (key !== 'id') {
          return (<Th
            sortable={(typeof val === 'object' && val?.value === undefined) ? false : true}
            key={'header-' + i}
            sorted={sortBy === key}
            reversed={reverseSortDirection}
            onSort={() => setSorting(key)}
          > {key}</Th>)
        }
        else return null;
      })
    }
  </tr>)

  const generateTableRows = (data: RowData[]) => {
    return data?.map((row: RowData, i: number) => {
      return (
        <tr key={row.id + '-' + i} className={classes.itemHover}>
          {Object.entries(row).map(([key, value], i) => {
            if (key !== 'id') {
              return (
                <td
                  className={classes.td}
                  style={{ width: value?.colWidth ? value?.colWidth : undefined }}
                  key={'td-' + i + value}
                  onClick={() => {
                    if (value?.view?.props?.onChange === undefined && value?.view?.props?.onClick === undefined) { handleClickItem(row.id); }
                  }}
                >
                  <span key={'span-' + i + value} className={!value?.view?.props?.onChange || !value?.view?.props?.onClick ? classes.itemTextHover : undefined}>
                    {value?.view ? value.view : value}
                  </span>
                </td>
              );
            } else { return null; }
          })}
        </tr>
      );
    });
  };

  const rows = count ? generateTableRows(sortedData) : generateTableRows(sortedData.slice(indexOfFirstPost, indexOfLastPost));

  const changePage = async (page: number) => {
    try {
      if (count) await getData(page);
      setActivePage(page);
    } catch (error) { console.error(error) }
  }

  return (
    <Container>
      <Group position={'apart'}>
        {
          componentButton ?
            componentButton
            :
            <Button disabled={disableButton} size="xs" color='cyan' leftIcon={(<SquarePlus size={15} />)} compact onClick={() => handleClickNew && handleClickNew(null)}>
              {newButtonText ? newButtonText : 'New'}
            </Button>
        }
        {
          changeSearchBar ?
            changeSearchBar
            :
            <TextInput
              placeholder="Search..."
              size="xs"
              icon={<IconSearch size={14} stroke={1.5} />}
              value={search}
              onChange={handleSearchChange}
            />
        }
      </Group>

      {data?.length > 0 ?
        <Table
          horizontalSpacing="md"
          verticalSpacing="xs"
          sx={{ tableLayout: tableLayout, minWidth: width ? width : 700 }}
        >
          <thead>
            {header}
          </thead>
          <tbody>
            {rows?.length > 0 ? (rows)
              :
              (<tr>
                <td colSpan={data?.length > 0 ? Object.keys(data[0]).length : undefined}>
                  <Text weight={500} align="center">Nothing found</Text></td>
              </tr>)}
          </tbody>
        </Table>
        : <Text weight={500} align="center">
          There is no data to display!
        </Text>
      }
      {(sortedData?.length > postsPerPage || count) && (
        <Center>
          <Pagination mt='xl' page={activePage} siblings={2} onChange={(page: number) => changePage(page)} initialPage={1}
            total={
              count ?
                Math.ceil(count / postsPerPage)
                :
                Math.ceil(sortedData?.length / postsPerPage)
            }
          />
        </Center>
      )}
    </Container>
  );
});