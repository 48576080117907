import { Button, Group, Modal, Select } from '@mantine/core'
import { DatePicker, TimeInput } from '@mantine/dates';
import { IconCalendar, IconClock } from '@tabler/icons';
import { useForm } from '@mantine/form';
import { adminCreateRecord } from '../../../exports/fetch/ArchiveFetch';
import { AuthStore } from '../../../stores/authStore';

const ArchiveNewForm = ({ templateListData, handleSubmit }) => {

    const form = useForm({
        initialValues: {
            createdBy: AuthStore.username,
            boardId: '',
            date: new Date(),
            time: new Date()
        }
    });

    return (
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <Select
                required
                label="Select a board to archive"
                placeholder="Boards"
                data={templateListData.map((template) => {
                    return (
                        { key: template.id, value: template.id, label: template.templateName }
                    )
                })}
                {...form.getInputProps('boardId')}
            />
            <DatePicker
                required
                label="Select date of archive"
                placeholder="Calendar"
                icon={<IconCalendar size={16} />}
                {...form.getInputProps('date')}
            />
            <TimeInput
                required
                label="Select time of archive"
                format="12"
                icon={<IconClock size={16} />}
                {...form.getInputProps('time')}
            />
            <Group position="right" mt="md">
                {
                    !form.values.boardId || !form.values.date || !form.values.time ?
                        <Button type="submit" disabled>Submit</Button>
                        :
                        <Button type="submit">Submit</Button>
                }
            </Group>
        </form>
    )
}

export default ArchiveNewForm
