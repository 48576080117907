import { Button, Container } from '@mantine/core';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Lock } from 'tabler-icons-react';

const MagnetFormADPLocked = (onSubmit: any) => {
    const navigate = useNavigate();

    return (
        <Container style={{ paddingTop: '15px', position: 'relative', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
            <Lock size={60} color={'#000000'} />
            <h3>You do not have an active subscription with this plugin</h3>
            {/* <Button onClick={() => {
                navigate('/admin/plugins')
                onSubmit('magnetForm');
            }}>
                Click here to learn more!
            </Button> */}
        </Container>
    )
}

export default MagnetFormADPLocked
