import { action, makeAutoObservable, computed, makeObservable, observable } from "mobx"
import { DeleteRole, GetRoles, PostRole, PutRole } from "../../../exports/fetch/RoleFetch";

export class RoleStoreClass {
    roles: RoleClass[] = [];

    constructor() {
        makeObservable(this, {
            roles: observable,
            addRole: action,
            updateRole: action,
            getRoles: action,
            deleteRole: action,
            rolesForSelectList: computed
        })
    }
    addRole(newRole: RoleClass) {
        PostRole(newRole).then((data) => {
            this.roles.push(data);
        })
    }
    updateRole(role: RoleClass) {
        PutRole(role).then((success) => {
            if (!success) return;
            this.roles = this.roles.map(r => r.id === role.id ? role : r);
        })

    }
    getRoles() {
        GetRoles().then((data) => {
            this.roles = data;
        })
    }
    get rolesForSelectList() {
        return this.roles.map(r => {
            return {
                value: r.id,
                label: r.name
            }
        })

    }
    getRoleById(id: string) {
        return this.roles.find(r => String(r.id) === id)
    }
    deleteRole(id: string) {
        DeleteRole(id).then((success) => {
            if (!success) return;
            this.roles = this.roles.filter(r => String(r.id) !== id);
        })
    }
}
export const RoleStore = new RoleStoreClass();

export class RoleClass {
    id?: number;
    name: string;
    adminPermissions: AdminPermissions;
    viewBoards: ViewBoardPermissions[];

    constructor(name: string) {
        // //this.id = Math.floor(1000 + Math.random() * 9000);
        this.name = name;
        this.adminPermissions = new AdminPermissions();
        this.viewBoards = [];
        makeObservable(this, {
            id: false,
            name: observable,
            adminPermissions: observable,
            viewBoards: observable,
            // addBoard: action,
        })
    }
    // addBoard(id: number){
    //     const newBoard = new ViewBoardPermissions(id);
    //     this.viewBoards.push(newBoard);
    // }

}

export class ViewBoardPermissions {
    boardId: number;
    canModify: boolean;
    canCreateMagnets: boolean;
    canSetDefaults: boolean;
    canLoadDefaults: boolean;
    canArchive: boolean;
    canClearBoard: boolean;

    constructor(boardId: number) {
        this.boardId = boardId;
        this.canModify = false;
        this.canCreateMagnets = false;
        this.canSetDefaults = false;
        this.canLoadDefaults = false;
        this.canArchive = false;
        this.canClearBoard = false;

    }
}
export class AdminPermissions {
    userPermissions: CrudPermissions;
    boardPermissions: CrudPermissions;
    magnetPermissions: CrudPermissions;
    magnetGroupPermissions: CrudPermissions;
    rolePermissions: CrudPermissions;
    archivePermissions: CrudPermissions;

    constructor() {
        this.userPermissions = new CrudPermissions();
        this.boardPermissions = new CrudPermissions();
        this.magnetPermissions = new CrudPermissions();
        this.magnetGroupPermissions = new CrudPermissions();
        this.rolePermissions = new CrudPermissions();
        this.archivePermissions = new CrudPermissions();

    }
}
class CrudPermissions {
    canCreate: boolean;
    canEdit: boolean;
    canDelete: boolean;
    constructor() {
        this.canCreate = false;
        this.canEdit = false;
        this.canDelete = false;
    }
}
