import TemplateDesignerGrid from "./TemplateDesignerGrid";
import TemplateDesignerToolPanel from "./TemplateDesignerToolPanel";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CloseButton, Container, Group, Modal, Text, Title, Space, Button, Divider, LoadingOverlay } from '@mantine/core';
import useTemplateDesigner from "../../../contexts/useTemplateDesigner";
import { SaveButton } from "../../SaveButton";
import useTemplates from "../../../contexts/useTemplates";
import { useDidUpdate } from "@mantine/hooks";

function TemplateDesigner() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [showSaveModal, setShowSaveModal] = useState(false);
    const {
        templateDesign,
        getTemplateDesignById,
        loadEmptyTemplate,
        saved,
        setSaved,
        saving,
        saveTemplate,
        clearTemplateDesign,
        loading,
        setLoading
    } = useTemplateDesigner();
    const { createTemplate } = useTemplates();

    const handleCloseDesigner = (ignoreChanges) => {
        if (saved || ignoreChanges) navigate('/admin/boards');
        else setShowSaveModal(true);
        // clearTemplateDesign();
    }

    useEffect(() => {
        if (id !== undefined) {
            getTemplateDesignById(id);
            //setSaved(true);
        }
        else {
            loadEmptyTemplate();
        }

        return () => {
            clearTemplateDesign();
        }
    }, [])

    useDidUpdate(() => {
        setSaved(false);
    }, [templateDesign])

    return (
        <>
            {templateDesign && (<TemplateDesignerToolPanel />)}
            <Container w='100%' fluid>
                <LoadingOverlay visible={loading} />
                <Group position='right' style={{ position: 'absolute', top: '60px', right: '300px' }}><CloseButton size='xl' onClick={() => handleCloseDesigner(false)} /></Group>

                <Modal opened={showSaveModal} withCloseButton={true} onClose={() => setShowSaveModal(false)}>
                    <Title align='center' order={5}>Unsaved Changes</Title>
                    <Text align='center'>Do want to save your recent changes?</Text>
                    <Space h='xl' />
                    <Divider />
                    <Space h='xl' />
                    <Group position='apart'>
                        <SaveButton boolSaveOrCreate={Boolean(templateDesign?.id)}
                            data={templateDesign}
                            saveFunction={saveTemplate}
                            createFunction={createTemplate}
                            loading={saving} disabled={saved}
                        />
                        <Button color={saved ? 'cyan' : 'red.4'} onClick={() => handleCloseDesigner(true)}>{saved ? "Continue" : "Discard Changes"}</Button>
                    </Group>
                </Modal>
                <Title align='center' order={3}>Template Designer</Title>
                {templateDesign && !loading && (<TemplateDesignerGrid />)}

            </Container>
        </>
    );
}

export default TemplateDesigner;