import { useState, useEffect } from 'react';
import { Magnet } from './MagnetComponent.js';
import DroppableCell from './DroppableCellComponent.js';
import { useDrop } from 'react-dnd';
//import { Table } from 'react-bootstrap';
import { SimpleGrid, Table, Title, useMantineTheme } from '@mantine/core';
import useAuth from '../../contexts/contextHooks/useAuth.js';
import { AuthStore } from '../../stores/authStore.ts';
import { Magnet2023 } from './MagnetComponent2023.tsx';


function Template(props) {
    const theme = useMantineTheme();
    const { roleData } = useAuth()

    return (<>
        <Table
            sx={(theme) => ({
                [theme.fn.smallerThan('sm')]: { minWidth: '90%' },
            })}
            style={{
                maxWidth: props.board.rows[0].cells.length * 10 > 98 ? '98%' : String(props.board.rows[0].cells.length * 10) + '%',
                minWidth: '60%',
                margin: 'auto',
                padding: '10px',
                border: '1px solid black',
                fontSize: '10px',
            }}>
            {
                props.board.showTemplateHeader && (
                    <thead><tr><th style={{ border: theme.colorScheme === 'dark' ? '1px solid #708291' : '1px solid black', textAlign: 'center' }} colSpan={props.board.rows[0].cells.length}><Title order={5}>{props.board.templateHeader}</Title></th></tr></thead>
                )
            }
            <tbody>

                {props.board.rows.map((row, yIndex) => {

                    return (<tr cols={row.cells.length} key={yIndex} spacing={0}

                        style={{ height: props.rowHeight }}>
                        {row.cells.map((cell, xIndex) => {
                            const CellTag = cell.header ? `th` : `td`;

                            if (cell.colSpan > 0)
                                return (
                                    <CellTag
                                        colSpan={cell.colSpan}
                                        key={xIndex}
                                        style={{
                                            //backgroundColor: cell.droppable && 'Cyan',
                                            fontWeight: cell.header && 'bold',
                                            border: theme.colorScheme === 'dark' ? '1px solid #708291' : '1px solid black',
                                            height: 'calc(' + props.rowHeight + ')',
                                            padding: cell.magnet ? 1 : 0,
                                            width: String(100 / row.cells.length) + '%',
                                            color: theme.colorScheme === 'dark' ? '#F1F2F2' : null,
                                            textAlign: cell.header ? 'center' : ''
                                        }}

                                    >
                                        {
                                            cell.magnet ?
                                                (
                                                    <Magnet2023 setModalOpened={() => { }} sourceLoc="board" key={xIndex + ',' + yIndex} coordinates={{ x: xIndex, y: yIndex }} data={cell.magnet} />
                                                )
                                                :
                                                cell.droppable && AuthStore.role?.viewBoards?.filter(b => {
                                                    return b.boardId === props.board.id
                                                })[0]?.canModify ?
                                                    (<DroppableCell destinationLoc="board" key={xIndex + ',' + yIndex} coordinates={{ x: xIndex, y: yIndex }} moveMagnet={props.moveMagnet}>{cell.text && cell.text}</DroppableCell>)
                                                    :
                                                    cell.text && cell.text
                                        }
                                    </CellTag>
                                )
                            else return null;
                        })}

                    </tr>)
                })}
            </tbody>
        </Table >
    </>);
}

export default Template;