import { showNotification } from "@mantine/notifications";
import { createContext, useEffect, useRef, useState } from "react";
import { DeleteUser, GetUsersFetch, NewUser } from "../exports/fetch/UserFetch";

const UserContext = createContext({});

export const UserProvider = ({ children }) => {
    const [userList, setUserList] = useState([]);
    const [loading, setLoading] = useState(true);
    const refreshInterval = useRef(null);

    const getUsers = async () => {
        const data = await GetUsersFetch();
        setUserList(data);
        setLoading(false);
        return;
    }

    const newUser = async (data) => {
        setLoading(true);
        await NewUser(data);
        setUserList(prevList => [...prevList, data])
        showNotification({
            title: 'Create',
            message: 'User  ' + data.username + ' was created'
        });
    }

    const deleteUser = (userId) => {
        DeleteUser(userId)
            .then(() => {
                setUserList(prevList => [...prevList.filter(u => {
                    return u.id !== userId
                })]);
                showNotification({
                    title: 'Delete',
                    message: 'User ' + ' was deleted'
                });
            });
    }

    const stopUserListRefresh = () => {
        clearInterval(refreshInterval.current);
    }

    const startUserListRefresh = () => {
        setLoading(true);
        getUsers();
        refreshInterval.current = setInterval(() => {
            getUsers();
        }, 10000);
    }

    useEffect(() => {
        return () => clearInterval(refreshInterval.current);
    }, []);

    return (
        <UserContext.Provider value={{ getUsers, newUser, deleteUser, startUserListRefresh, stopUserListRefresh, userList, setUserList, loading }}>
            {children}
        </UserContext.Provider>
    );
}

export default UserContext;