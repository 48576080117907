import { useEffect, useState } from 'react';
import { createStyles, Navbar, UnstyledButton, Tooltip, Title, Image, Text, Flex, Anchor } from '@mantine/core';
import {
    IconHome2,
    IconUser,
    IconChalkboard,
    IconMagnet,
    IconArchive,
    IconPuzzle
} from '@tabler/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../images/mb-full-trans.png'
import useSettings from '../../contexts/contextHooks/useSettings';
import { Lock } from 'tabler-icons-react';
import CustomToolTip from '../Utilities/CustomToolTip';
import { AuthStore } from '../../stores/authStore';
import { isConstructorDeclaration } from 'typescript';

const useStyles = createStyles((theme) => ({
    wrapper: {
        display: 'flex',
    },

    aside: {
        flex: '0 0 60px',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRight: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]}`,
    },

    main: {
        flex: 1,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },

    mainLink: {
        width: 44,
        height: 44,
        borderRadius: theme.radius.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
        },
    },

    mainLinkActive: {
        '&, &:hover': {
            backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
            color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
        },
    },

    title: {
        boxSizing: 'border-box',
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        marginBottom: theme.spacing.xl,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        padding: theme.spacing.md,
        paddingTop: 18,
        height: 60,
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]}`,
    },

    logo: {
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        height: 60,
        paddingTop: theme.spacing.md,
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
            }`,
        marginBottom: theme.spacing.xl,
    },

    link: {
        boxSizing: 'border-box',
        display: 'block',
        textDecoration: 'none',
        borderTopRightRadius: theme.radius.md,
        borderBottomRightRadius: theme.radius.md,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        padding: `0 ${theme.spacing.md}px`,
        fontSize: theme.fontSizes.sm,
        marginRight: theme.spacing.md,
        fontWeight: 500,
        height: 44,
        lineHeight: '44px',

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
    },

    linkActive: {
        '&, &:hover': {
            borderLeftColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor })
                .background,
            backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor })
                .background,
            color: theme.white,
        },
    },
}));

export function AdminNavbar(props) {
    //const [links, setLinks] = useState(props.links)
    const location = useLocation();
    const initialLinks = [
        { icon: IconHome2, label: 'Home', submenu: [{ menuLabel: 'View Live Boards', path: '/boards' }] },
        { icon: IconChalkboard, label: 'Board Management', submenu: [{ menuLabel: 'View Boards', path: 'boards', disabled: !AuthStore.role?.adminPermissions?.boardPermissions?.canCreate }] },
        {
            icon: IconMagnet, label: 'Magnet Management', submenu:
                [
                    { menuLabel: 'MagniGroups', path: 'magnigroups', disabled: !AuthStore.role?.adminPermissions?.magnetGroupPermissions?.canCreate },
                    { menuLabel: 'View Magnets', path: 'magnets', disabled: !AuthStore.role?.adminPermissions?.magnetPermissions?.canCreate }
                ]
        },

        {
            icon: IconUser, label: 'User Administration', submenu: [
                { menuLabel: 'View Users', path: 'users', disabled: !AuthStore.role?.adminPermissions?.userPermissions?.canCreate },
                { menuLabel: 'View Roles', path: 'roles', disabled: !AuthStore.role?.adminPermissions?.rolePermissions?.canCreate }
            ]
        },
        {
            icon: IconArchive, label: 'Archive Management', submenu: [
                { menuLabel: 'View Archives', path: 'archives', disabled: !AuthStore.role?.adminPermissions?.archivePermissions?.canCreate },
                { menuLabel: 'Schedule Archive', path: 'archivescheduler', disabled: !AuthStore.role?.adminPermissions?.archivePermissions?.canCreate }
            ],
        },
        {
            icon: IconPuzzle, label: 'Plugin Management', submenu: [
                { menuLabel: 'Integrations', path: 'integrations', disabled: true /*disabled: !AuthStore.role?.adminPermissions?.archivePermissions?.canCreate*/ },
                { menuLabel: 'View Plugins', path: 'plugins', /*disabled: !AuthStore.role?.adminPermissions?.archivePermissions?.canCreate*/ },
            ],
        },
    ];
    const [links, setLinks] = useState([]);
    const { classes, cx } = useStyles();
    const [active, setActive] = useState('Board Management');
    const [activeLink, setActiveLink] = useState();
    const [selectedSubmenu, setSubmenu] = useState();
    const navigate = useNavigate();
    const { fullNavbar } = useSettings();


    useEffect(() => {
        setLinks(initialLinks);

        let availableLinks = initialLinks?.filter((link, index) => {
            // index > 0 ? !link[index]?.submenu?.disabled ? link : null : null
            if (index > 0) {
                if (link.submenu.filter(sub => !sub.disabled).length > 0) {
                    return link;
                }
            }
        });
        if (location.pathname.endsWith('/admin')) {
            navigate(availableLinks[0]?.submenu[0]?.path);
        }
        availableLinks.forEach(link => {
            link.submenu.forEach(sub => {
                if (location.pathname.endsWith(sub.path)) {
                    setActiveLink(sub);
                    setActive(link?.label);
                    setSubmenu(link?.submenu);
                    return;
                }

            })
        })
    }, [AuthStore.role, location.pathname])


    const mainLinks = links.map((link) => {
        return (
            < Tooltip label={link.label} position="right" withArrow transitionDuration={0} key={link.label} >
                <UnstyledButton
                    onClick={() => {
                        setActive(link.label)
                        setSubmenu(link.submenu)
                        if (link?.label === 'Home') navigate(link?.submenu?.path);
                    }}
                    className={cx(classes.mainLink, { [classes.mainLinkActive]: link.label === active })}
                >
                    <link.icon stroke={1.5} />
                </UnstyledButton>
            </Tooltip >
        )
    });

    const submenus = selectedSubmenu && selectedSubmenu.map((submenu, index) => {
        return (
            <Anchor
                underline={false}
                className={cx(classes.link, { [classes.linkActive]: activeLink === submenu })}
                onClick={submenu.disabled ? null : (event) => {
                    event.preventDefault();
                    setActiveLink(submenu);
                    navigate(submenu.path)
                }}
                key={index}
            >

                {submenu.disabled ?
                    (<CustomToolTip text='Locked due to permissions' position='right' icon={<Lock />}>{submenu.menuLabel}</CustomToolTip>) :
                    (submenu.menuLabel)}

            </Anchor>
        )
    });

    return (<>
        {AuthStore.role ? (
            <Navbar height='100%' width={fullNavbar ? { sm: 300 } : 0}>
                <Navbar.Section grow className={classes.wrapper}>
                    <div className={classes.aside}>
                        <div className={classes.logo}>
                            <Image width={30} height={30} disabled src={logo} alt="Logo" />
                        </div>
                        {mainLinks}
                    </div>
                    <div className={classes.main}>
                        <Title order={4} className={classes.title}>
                            {active}
                        </Title>
                        {submenus}
                    </div>
                </Navbar.Section>
            </Navbar>
        ) : null}

    </>);
}