import { action, computed, makeObservable, observable } from "mobx"
import { AdminPermissions, RoleClass } from "../Components/AdminConsole/RoleSettings/RoleStore";
import { LogoutRequest } from "../exports/fetch/AuthFetch";
import { API_URL } from "../exports/fetch/GlobalFetchSettings";

export class AuthStoreClass {
    username?: string;
    role?: RoleClass;
    accessToken?: string;

    constructor() {
        makeObservable(this, {
            username: observable,
            role: observable,
            accessToken: observable,
            setAuth: action,
            isAdmin: computed,
            allowedPages: computed,
            logout: action,
        })
    }
    setAuth(username: string, roleData: RoleClass, accessToken: string) {
        this.username = username.toLowerCase();
        this.role = roleData;
        this.accessToken = accessToken;
    }
    get isAdmin() {

        if (!this.role) return false;
        const adminPermissions = Object.keys(this.role.adminPermissions).filter(p => {
            if (this.role?.adminPermissions[p as keyof AdminPermissions].canCreate)
                return p;
        })
        if (adminPermissions.length > 0)
            return true;
        else return false
    }

    get allowedPages() {
        let ary: string[] = [
            '/boards'
        ];
        if (this.role) {
            Object.keys(this.role?.adminPermissions).forEach((key) => {
                if (this.role?.adminPermissions[key as keyof AdminPermissions].canCreate) {
                    switch (key) {
                        case 'boardPermissions':
                            ary.push('/admin/boards');
                            break;
                        case 'magnetGroupPermissions':
                            ary.push('/admin/magnigroups');
                            break;
                        case 'magnetPermissions':
                            ary.push('/admin/magnets');
                            break;
                        case 'userPermissions':
                            ary.push('/admin/users');
                            break;
                        case 'rolePermissions':
                            ary.push('/admin/roles');
                            break;
                        case 'archivePermissions':
                            ary.push('/admin/archives');
                            break;
                    }
                }
            });
        }
        if (ary.length > 1) ary.push('/admin');
        return ary;
    }

    login() {

    }

    async logout() {
        this.accessToken = '';
        this.username = '';
        this.role = undefined;
        await LogoutRequest();
    }

    async refreshRole() {
        //fetch by id
        //this.role = GetRoleById(this.role.id);
    }

}
export const AuthStore = new AuthStoreClass();