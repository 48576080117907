import { createContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteTemplate, GetTemplates, PostTemplate, PutCell, PutTemplate, SetTemplateStatusFromList } from '../exports/fetch/TemplateFetch';
import useAuth from './contextHooks/useAuth';

const TemplateContext = createContext({});

export const TemplateProvider = ({ children }) => {
    const [templateList, setTemplateList] = useState([]);
    const [loadingTemplate, setLoadingTemplate] = useState(true);
    const { getToken } = useAuth();
    const navigate = useNavigate();
    const refreshInterval = useRef(null);

    const getTemplates = async () => {
        const data = await GetTemplates();
        setTemplateList(data);
        setLoadingTemplate(false);
        return data;
    }

    const createTemplate = (template) => {
        setLoadingTemplate(true);
        PostTemplate(template)
            .then(() => {
                setTemplateList(prevList => [...prevList, template.templateData ? template.templateData : template])
                navigate("/admin/boards");
                setLoadingTemplate(false);
                //getTemplates();
            });
    }

    const updateTemplate = (template) => {
        PutTemplate(template)
            .then(() => {
                getTemplates();
            });
    }

    const deleteTemplate = async (id) => {
        DeleteTemplate(id)
            .then(() => {
                setTemplateList(prevList => prevList.filter(t => {
                    return t.id !== id
                }));
            });
    }

    const changeTemplateStatus = (template) => {
        template.isActive = !template.isActive;
        let templateData = { id: template.id, isActive: template.isActive };
        PutTemplate(templateData).then(() => {
            setTemplateList(prevList => prevList.map((temp => temp.id === template.id ? { ...temp, isActive: template.isActive } : temp)));
        })
    };

    const stopTemplateListRefresh = () => {
        clearInterval(refreshInterval.current);
    }

    const startTemplateListRefresh = () => {
        setLoadingTemplate(true);
        getTemplates();
        refreshInterval.current = setInterval(() => {
            getTemplates();
        }, 10000);
    }

    useEffect(() => {
        return () => clearInterval(refreshInterval.current);
    }, []);

    return (<>
        <TemplateContext.Provider value={{
            templateList,
            updateTemplate,
            createTemplate,
            deleteTemplate,
            changeTemplateStatus,
            loadingTemplate,
            setLoadingTemplate,
            stopTemplateListRefresh,
            startTemplateListRefresh,
            getTemplates
        }}>
            {children}
        </TemplateContext.Provider>
    </>)
}

export default TemplateContext; 