import { API_URL, customErrorNotification, isValid } from './GlobalFetchSettings.js'
import { AuthStore } from '../../stores/authStore.ts';
import { privateFetch as fetch } from '../../hooks/usePrivateFetch.js';

const BOARD_URL = API_URL + '/Boards';
const TEMPLATE_URL = API_URL + '/Template';
const MAGNET_URL = API_URL + '/Magnet';
const CELL_URL = API_URL + '/Cell';

//get Template by id
//get request
export const GetTemplates = async () => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const response = await fetch(TEMPLATE_URL, requestOptions);
        const data = await response.json();
        return data;
    }
    catch (e) {
        console.error("ERROR: " + e)
    }
};

export const GetTemplateById = async (id,) => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const response = await fetch(TEMPLATE_URL + '/' + id, requestOptions);
        return response.json();
    }
    catch (e) {
        console.error("ERROR: " + e)
    }
};

export const getActiveTemplates = async (setData, setLoading, setErr) => {
    setLoading(true);
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    fetch(TEMPLATE_URL + '/GetActiveTemplates', requestOptions)
        .then((response) => {
            if (response.status == 200) {
                response.json()
                    .then(data => {
                        setData(data)
                        setLoading(false);
                    })
            }
            if (response.status == 401) {
                setLoading(false);
                setErr('Permissions Required');
            }
            else {
                //get error back
                setLoading(false);
            }

        })
        .catch(ex => console.error(ex))
};

export const getActiveBoardWithPouchById = async (id) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    try {
        const res = await fetch(TEMPLATE_URL + '/GetActiveBoardWithPouchById/' + id, requestOptions);
        if (!res.ok) return res.status;
        const data = await res.json();
        return data;
    }
    catch (err) { console.error(err) }
}

export const getActiveTemplateById = async (id, setViewData) => {
    try {
        const response = await fetch(TEMPLATE_URL + '/GetActiveTemplateById/' + id);
        const newData = await response.json();
        setViewData(newData);

        return newData;
    }
    catch (e) {
        console.error("ERROR: " + e)
    }
};

//get unlinked Templates (those without a boardId key)
export const GetUnlinkedTemplates = async (setData) => {
    try {
        const response = await fetch(TEMPLATE_URL + "/GetUnlinkedTemplates");
        const newData = await response.json();
        setData(newData);
    }
    catch (e) {
        console.error("ERROR: " + e)
    }
};

export async function loadTemplate(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    try {
        let res = await fetch(TEMPLATE_URL + '/LoadTemplatePreset/' + id, requestOptions)
        if (res.ok) return res.json();
        else if (res.status === 404) customErrorNotification(res, 'No default preset is found for this board.')
        else customErrorNotification(res);
    } catch (err) {
        console.error(err);
    }
}

export async function saveTemplate(template) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(template)
    };
    try {
        let res = await fetch(TEMPLATE_URL + '/SaveTemplatePreset/' + template.id, requestOptions)
        if (res.ok) return res;
        else customErrorNotification(res);
        return false;
    } catch (err) {
        console.error(err);
    }
}

export async function ResetBoard(id) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    await fetch(CELL_URL + '/ResetBoard/' + id, requestOptions)
    return;
}

//post request
export async function PostTemplate(template) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(template)
    };
    await fetch(TEMPLATE_URL, requestOptions)
    return;
}

export async function putTemplateWithMagnets(template, setData) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(template)
    };
    fetch(TEMPLATE_URL + "/PutTemplate/" + template.id, requestOptions)
        .then(function (response) {
            getActiveBoardWithPouchById(template.id, setData);
        })
}
//put Template
export async function PutTemplate(template) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(template)
    };
    const response = await fetch(TEMPLATE_URL + "/" + template.id, requestOptions)
    return response;
}

export const SetTemplateStatusFromList = async (template) => {
    PutTemplate(template);
};

//put -> unlink Template
export async function putUnlinkTemplate(TemplateId) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',

        },
        body: JSON.stringify({ boardId: null })
    };
    const response = await fetch(TEMPLATE_URL + "/PutUnlinkTemplate/" + TemplateId, requestOptions)
    return response.json();
}

//delete requeset
export async function DeleteTemplate(TemplateId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    await fetch(TEMPLATE_URL + "/" + TemplateId, requestOptions)
    return;
}

//cell requests
export async function PutCell(cell, templateId) {
    cell.templateId = templateId;
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(cell)
    };
    await fetch(CELL_URL + '/' + cell.id, requestOptions)
    return;
}

export async function UpdateCells(board) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(board)
    };
    let res = await fetch(CELL_URL + '/UpdateCells/' + board.id, requestOptions)
    if (res.ok) return res;
    else customErrorNotification(res);
    return;
}
