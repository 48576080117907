import { useEffect, useState } from 'react';
import { Center, Drawer, Grid, Table, Title, Pagination, Group, Select } from '@mantine/core';
import { Magnet } from './MagnetComponent';
import SearchBar from '../SearchBar';
import { getAllByLabelText } from '@testing-library/react';
import { Magnet2023 } from './MagnetComponent2023';

function MagnetSideBar({ magnetGroups, drawer, magnets, openState, word }) {
    const cellStyle = {
        height: '30px',
        padding: '2px 5px',
        width: '40%',
        border: 'none'
    }
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(40);
    const [groupFilter, setGroupFilter] = useState('0');
    const [sideBarMagnets, setSideBarMagnets] = useState([]);
    const [numRows, setNumRows] = useState();

    const [localMagnetGroups, setLocalMagnetGroups] = useState([]);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;

    useEffect(() => {
        setSideBarMagnets(magnets);
        setLocalMagnetGroups([{ name: 'All MagniGroups', id: '0' }, ...magnetGroups]);
    }, [magnetGroups])

    useEffect(() => {
        setCurrentPage(1);
        if (groupFilter !== '0' || word.wordEntered) handleSideBarMagnetsFilter();
        else setSideBarMagnets(magnets);
    }, [word.wordEntered, groupFilter, magnets])

    // useEffect(() => {
    //     if(!groupFilter) setSideBarMagnets(magnets)
    //     else setSideBarMagnets(magnets.filter(e => e.magnetGroupId === groupFilter))
    // }, [groupFilter])

    const handleSideBarMagnetsFilter = () => {
        setSideBarMagnets(() => {
            return magnets.filter((mag) => {
                if (groupFilter !== '0' && word.wordEntered) {
                    if (mag.magnetGroupId === groupFilter && mag.magnetName.toUpperCase().includes(word.wordEntered.toUpperCase())) return mag;
                }
                else if (groupFilter === '0' && word.wordEntered) {
                    if (mag.magnetName.toUpperCase().includes(word.wordEntered.toUpperCase())) return mag;
                }
                else if (groupFilter !== '0' && !word.wordEntered) {
                    if (mag.magnetGroupId === groupFilter) return mag;
                }

            })
        })
    }

    useEffect(() => {
        //setSideBarMagnets(magnets);
        if (currentPage != 1 && sideBarMagnets.length <= (currentPage - 1) * postsPerPage) {
            setCurrentPage((prevState) => prevState - 1);
        }
        let currentPosts = '';
        if (word.wordEntered.length > 0) currentPosts = sideBarMagnets?.slice(indexOfFirstPost, indexOfLastPost);
        else currentPosts = sideBarMagnets.slice(indexOfFirstPost, indexOfLastPost);

        const cols = Math.ceil(currentPosts.length / 2);
        const rows = [...Array(cols).keys()].map(c => currentPosts.filter((_, i) => i % cols === c));
        setNumRows(rows);
    }, [sideBarMagnets]);

    return (
        <>
            <Drawer
                size='23%'
                opened={openState.sideBarOpen}
                onClose={() => openState.setSideBarOpen(false)}
                position='right'
                shadow='-6px 10px 24px 0px rgba(0,0,0,0.68)'
                style={{ visibility: drawer.drawerVisibility }}
            >
                <Center><Title order={6}>MagniPouch</Title></Center>
                <Group position='center' style={{ paddingTop: '10px' }} p={5} grow noWrap>
                    <Select
                        size='xs'
                        radius='md'
                        defaultValue={'0'}
                        //label="MagniGroup"
                        onChange={(value) => setGroupFilter(value)}
                        placeholder="Choose MagniGroup"
                        data={localMagnetGroups && localMagnetGroups.map(group => {
                            return { value: group.id, label: group.name }
                        })}
                    />
                    <SearchBar
                        wordEntered={word.wordEntered}
                        setWordEntered={word.setWordEntered}
                        placeholder='Search Magnets..'
                        data={magnets}
                        searchType='magnet'
                    />
                </Group>
                <Grid columns={2} justify='center' style={{ margin: '10px' }}>
                    <Grid.Col span={2}>
                        <Table >
                            <tbody>
                                {

                                    sideBarMagnets && sideBarMagnets.length > 0 ?
                                        numRows && numRows.map((row, i) => {
                                            return (
                                                <tr key={i}>
                                                    {
                                                        row.map(magnet => {
                                                            return (
                                                                <td key={magnet.id} style={cellStyle}>
                                                                    <Magnet2023 drawerVisibility={drawer.drawerVisibility} setDrawerVisibility={drawer.setDrawerVisibility}
                                                                        openState={openState} sourceLoc="pouch" data={magnet}
                                                                    />
                                                                </td>)
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr><td style={{ textAlign: 'center' }}>Magnet Pouch is empty!</td></tr>
                                }
                            </tbody>
                        </Table>
                    </Grid.Col>
                </Grid>
                <Pagination
                    style={{ paddingTop: '30px' }}
                    page={currentPage}
                    onChange={setCurrentPage}
                    position="center"
                    total={
                        word.wordEntered.length > 0 ?
                            Math.ceil(sideBarMagnets?.length / postsPerPage)
                            :
                            Math.ceil(sideBarMagnets?.length / postsPerPage)
                    }
                />
            </Drawer>
        </>
    );


}
export default MagnetSideBar;