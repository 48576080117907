import { useEffect } from 'react';
import { Switch, Container, Title, LoadingOverlay } from '@mantine/core';
import { useNavigate } from "react-router-dom";
import useTemplates from '../../../contexts/useTemplates';
import useSettings from '../../../contexts/contextHooks/useSettings';
import { TableSort } from '../../Utilities/TableSort';

const TemplateList = () => {
    const {
        templateList,
        changeTemplateStatus,
        loading,
        stopTemplateListRefresh,
        startTemplateListRefresh
    } = useTemplates();
    const navigate = useNavigate();

    const { fullNavbar } = useSettings();
    useEffect(() => {
        startTemplateListRefresh();
        return () => stopTemplateListRefresh();
    }, [])


    return (
        <>
            <Container size="md" fluid pr={{ xl: fullNavbar ? '300px' : null }} style={{
                marginTop: '8vh',
            }}>
                <LoadingOverlay visible={loading} />
                <Title align="center" order={3}>Boards</Title>

                {(templateList && templateList.length > 0) && (
                    <TableSort
                        tableLayout='auto'
                        newButtonText='New Board'
                        handleClickNew={() => navigate('designer/new')}
                        handleClickItem={(templateId) => navigate('designer/' + templateId)}
                        data={templateList && templateList.map(t => {
                            return {
                                id: String(t.id),
                                "Board": t.templateName,
                                "Active": {
                                    value: t.isActive,
                                    view: (<Switch style={{ zIndex: 3 }} onLabel="On" offLabel="Off" size="sm" checked={t.isActive} onChange={() => changeTemplateStatus(t)} />)
                                },
                                "Size": {
                                    value: t.rows[0].cells.length * t.rows.length,
                                    view: t.rows[0].cells.length * t.rows.length + ' cells'
                                }
                            }
                        })}
                    />
                )}

            </Container>
        </>
    )
}

export default TemplateList