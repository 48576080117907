import { action, makeObservable, observable, runInAction, toJS } from "mobx";
import { adminCreateRecord, deleteArchiveRecord, GetLimitedRecords, GetRecordById, GetRecords } from "../../../exports/fetch/ArchiveFetch";
import { templateObject } from "./ArchiveSchedulerStore";

interface ArchiveObject {
    count: number,
    records: ArchiveTemplate[]
}

export type ArchiveTemplate = {
    id?: number,
    templateObject?: templateObject,
    timestamp?: string,
    createdBy?: string
}

export class ArchiveStoreClass {
    archives: ArchiveObject = { count: 0, records: [] };
    constructor() {
        makeObservable(this, {
            archives: observable,
            getArchives: action,
            getLimitedArchives: action,
            addArchive: action,
            deleteArchive: action
        })
    }

    async getArchiveById(id: number) {
        try {
            const data = await GetRecordById(id);
            return data;
        } catch (error) { console.error(error); }
    }

    getArchives() {
        GetRecords().then((data) => {
            this.archives = data;
        })
    }

    async getLimitedArchives(page: number) {
        try {
            const data = await GetLimitedRecords(page);
            this.archives = data;
        } catch (error) { console.error(error) }
    }

    async addArchive(archiveObject: ArchiveTemplate) {
        try {
            const createRecord = await adminCreateRecord(archiveObject);
            if (createRecord) this.archives.records.push(createRecord);
        } catch (error) { console.error(error) }
    }
    async deleteArchive(id: number) {
        try {
            const deleteArchive = await deleteArchiveRecord(id);
            if (deleteArchive) this.archives.records.filter((archive) => archive.id !== id);
        } catch (error) { console.error(error) }
    }
}

export const ArchiveObj = new ArchiveStoreClass();