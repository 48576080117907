import React, { ChangeEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Container, Box, Button, Checkbox, Group, Paper, TextInput, Divider, Collapse, List, Table, Select, Flex, ActionIcon, Tooltip, Text, SelectItem, CheckboxProps, useMantineTheme, Center, Space } from '@mantine/core';
import { FORM_INDEX, useForm } from '@mantine/form';
import { RoleStore, RoleClass, ViewBoardPermissions } from './RoleStore';
import { useNavigate, useParams } from 'react-router-dom';
import { Chalkboard, Check, ChevronDown, ChevronsRight, ChevronUp, InfoCircle, Minus, Plus, Settings, SettingsOff, Square, SquareCheck, Tilde, Trash, X } from 'tabler-icons-react';
import './rolesettings.css';
import { GearIcon } from '@primer/octicons-react';
import useTemplates from '../../../contexts/useTemplates';
import { isTemplateLiteral } from 'typescript';
import { useDidUpdate } from '@mantine/hooks';
import CustomToolTip from '../../Utilities/CustomToolTip';
import { openConfirmModal } from '@mantine/modals';

interface RoleListProps {

}

interface Template {
  id: number,
  templateName: string,
}
interface useTemplateObj {
  templateList?: Template[];
  getTemplates?: () => void;
}
interface selectOptions {
  value: string,
  label: string
}

const RoleForm = ({ }: RoleListProps) => {

  const theme = useMantineTheme();
  const { templateList, getTemplates }: useTemplateObj = useTemplates();
  const [showAdminSection, setShowAdminSection] = useState(true);
  const [selectedBoard, setSelectedBoard] = useState<string | null>();

  const [selectBoardOptions, setSelectBoardOptions] = useState<SelectItem[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    getTemplates !== undefined && getTemplates();
  }, [])

  const confirmDelete = async (id: string) => openConfirmModal({
    title: (<Center> Deleting Role</Center>),
    children: (<Text align='center'>Are you sure you wish to continue?</Text>),
    labels: { confirm: 'Yes - Delete', cancel: 'Cancel' },
    confirmProps: { color: 'red' },
    onConfirm: () => {
      id && RoleStore.deleteRole(id)
      navigate('/admin/roles', { replace: true })
    }
  })

  useDidUpdate(() => {
    if (templateList === undefined) return;
    setSelectBoardOptions(templateList?.map(t => {
      return {
        value: String(t.id),
        label: t.templateName
      }
    }))
  }, [templateList])

  const { id } = useParams();
  let newRole = undefined;
  if (id) newRole = RoleStore.getRoleById(id)
  if (!newRole) newRole = new RoleClass('');

  const form = useForm<RoleClass>({
    validateInputOnChange: [
      `viewBoards.${FORM_INDEX}.canCreateMagnets`,
      `viewBoards.${FORM_INDEX}.canSetDefaults`,
      `viewBoards.${FORM_INDEX}.canLoadDefaults`,
      `viewBoards.${FORM_INDEX}.canArchive`,
      `viewBoards.${FORM_INDEX}.canClearBoard`,
    ],
    initialValues: {
      ...newRole
    },
  });

  const addBoard = (id: string) => {
    const newObj = new ViewBoardPermissions(Number(id));
    let currentBoards = form.values.viewBoards
    currentBoards.push(newObj);
    form.setFieldValue('viewBoards', [...currentBoards]);
  }
  const removeBoard = (index: number) => {
    form.removeListItem('viewBoards', index);
  }
  const handleCanModifyClick = (index: number, isChecked: boolean) => {
    if (showBoardAdvancedSettings) return;
    form.setFieldValue('viewBoards.' + index + '.canCreateMagnets', isChecked);
    form.setFieldValue('viewBoards.' + index + '.canSetDefaults', isChecked);
    form.setFieldValue('viewBoards.' + index + '.canLoadDefaults', isChecked);
    form.setFieldValue('viewBoards.' + index + '.canArchive', isChecked);
    form.setFieldValue('viewBoards.' + index + '.canClearBoard', isChecked);
  }

  const handleSubmit = (values: RoleClass) => {
    if (id) RoleStore.updateRole(values);
    else RoleStore.addRole(values);
    navigate('/admin/roles', { replace: true })
  }

  let isThereUncheckedPermissions = false;
  form.values.viewBoards?.forEach(b => {
    return Object.entries(b).forEach((item, index) => {
      if (index < 2) return;
      if (b.canModify != item[1]) isThereUncheckedPermissions = true;
    })
  })
  const [showBoardAdvancedSettings, setShowBoardAdvancedSettings] = useState<boolean>(isThereUncheckedPermissions);

  return (
    <Container mt='lg'>
      <Paper shadow='md' p='md'>
        <form onSubmit={form.onSubmit((values: RoleClass) => handleSubmit(values))}>
          <TextInput
            required
            withAsterisk
            label="Role Name"
            placeholder="Role Name"
            {...form.getInputProps('name')}
          />
          <Divider label={<Button variant='subtle' compact leftIcon={<Settings />} rightIcon={showAdminSection ? <ChevronDown size={15} /> : <ChevronUp size={15} />} onClick={() => setShowAdminSection(prevState => !prevState)}>Administration</Button>} my='lg' />
          <Collapse in={showAdminSection}>
            <Table withBorder={false} ml={'5%'} w='60%' className='adminTable' verticalSpacing='sm'>
              <tbody>
                <tr>
                  <td>
                    <CustomToolTip text='Gives Permission to Create/Edit Boards in Admin Console'>
                      <Checkbox label='Boards' {...form.getInputProps('adminPermissions.boardPermissions.canCreate', { type: 'checkbox' })} />
                    </CustomToolTip>
                  </td>
                  <td>
                    <Collapse in={form.values.adminPermissions.boardPermissions.canCreate}>
                      <Checkbox label='Create/Edit' size='xs' disabled {...form.getInputProps('adminPermissions.boardPermissions.canCreate', { type: 'checkbox' })} />
                    </Collapse>
                  </td>
                  <td>
                    <Collapse in={form.values.adminPermissions.boardPermissions.canCreate}>
                      <Checkbox label='Delete' size='xs' {...form.getInputProps('adminPermissions.boardPermissions.canDelete', { type: 'checkbox' })} />
                    </Collapse>
                  </td>
                </tr>
                <tr>
                  <td>
                    <CustomToolTip text='Gives Permission to Create/Edit MagniGroups in Admin Console'>
                      <Checkbox label='MagniGroups' {...form.getInputProps('adminPermissions.magnetGroupPermissions.canCreate', { type: 'checkbox' })} />
                    </CustomToolTip>
                  </td>
                  <td>
                    <Collapse in={form.values.adminPermissions.magnetGroupPermissions.canCreate}>
                      <Checkbox label='Create/Edit' size='xs' disabled {...form.getInputProps('adminPermissions.magnetGroupPermissions.canCreate', { type: 'checkbox' })} />
                    </Collapse>
                  </td>
                  <td>
                    <Collapse in={form.values.adminPermissions.magnetGroupPermissions.canCreate}>
                      <Checkbox label='Delete' size='xs' {...form.getInputProps('adminPermissions.magnetGroupPermissions.canDelete', { type: 'checkbox' })} />
                    </Collapse>
                  </td>
                </tr>
                <tr>
                  <td>
                    <CustomToolTip text='Gives Permission to Create/Edit Magnets in Admin Console'>
                      <Checkbox label='Magnets' {...form.getInputProps('adminPermissions.magnetPermissions.canCreate', { type: 'checkbox' })} />
                    </CustomToolTip>
                  </td>
                  <td>
                    <Collapse in={form.values.adminPermissions.magnetPermissions.canCreate}>
                      <Checkbox label='Create/Edit' size='xs' disabled {...form.getInputProps('adminPermissions.magnetPermissions.canCreate', { type: 'checkbox' })} />
                    </Collapse>
                  </td>
                  <td>
                    <Collapse in={form.values.adminPermissions.magnetPermissions.canCreate}>
                      <Checkbox label='Delete' size='xs' {...form.getInputProps('adminPermissions.magnetPermissions.canDelete', { type: 'checkbox' })} />
                    </Collapse>
                  </td>
                </tr>
                <tr>
                  <td>
                    <CustomToolTip text='Gives Permission to Create/Edit Users in Admin Console'>
                      <Checkbox label='Users' {...form.getInputProps('adminPermissions.userPermissions.canCreate', { type: 'checkbox' })} />
                    </CustomToolTip>
                  </td>
                  <td>
                    <Collapse in={form.values.adminPermissions.userPermissions.canCreate}>
                      <Checkbox label='Create/Edit' size='xs' disabled {...form.getInputProps('adminPermissions.userPermissions.canCreate', { type: 'checkbox' })} />
                    </Collapse>
                  </td>
                  <td>
                    <Collapse in={form.values.adminPermissions.userPermissions.canCreate}>
                      <Checkbox label='Delete' size='xs' {...form.getInputProps('adminPermissions.userPermissions.canDelete', { type: 'checkbox' })} />
                    </Collapse>
                  </td>
                </tr>
                <tr>
                  <td>
                    <CustomToolTip text='Gives Permission to Create/Edit Roles in Admin Console'>
                      <Checkbox label='Roles' {...form.getInputProps('adminPermissions.rolePermissions.canCreate', { type: 'checkbox' })} />
                    </CustomToolTip>
                  </td>
                  <td>
                    <Collapse in={form.values.adminPermissions.rolePermissions.canCreate}>
                      <Checkbox label='Create/Edit' size='xs' disabled {...form.getInputProps('adminPermissions.rolePermissions.canCreate', { type: 'checkbox' })} />
                    </Collapse>
                  </td>
                  <td>
                    <Collapse in={form.values.adminPermissions.rolePermissions.canCreate}><Checkbox label='Delete' size='xs' {...form.getInputProps('adminPermissions.rolePermissions.canDelete', { type: 'checkbox' })} />
                    </Collapse>
                  </td>
                </tr>
                <tr>
                  <td>
                    <CustomToolTip text='Gives Permission to Create/Edit Archives in Admin Console'>
                      <Checkbox label='Archives' {...form.getInputProps('adminPermissions.archivePermissions.canCreate', { type: 'checkbox' })} />
                    </CustomToolTip>
                  </td>
                  <td>
                    <Collapse in={form.values.adminPermissions.archivePermissions.canCreate}>
                      <Checkbox label='Create/Edit' size='xs' disabled {...form.getInputProps('adminPermissions.archivePermissions.canCreate', { type: 'checkbox' })} />
                    </Collapse>
                  </td>
                  <td>
                    <Collapse in={form.values.adminPermissions.archivePermissions.canCreate}><Checkbox label='Delete' size='xs' {...form.getInputProps('adminPermissions.archivePermissions.canDelete', { type: 'checkbox' })} />
                    </Collapse>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Collapse>
          <Divider label={<Button variant='subtle' sx={{ '&:hover': { backgroundColor: 'transparent !important' }, cursor: 'default', '&:active': 'none' }} compact leftIcon={<Chalkboard />} >Board Access</Button>} my='lg' />

          <Group position='left' ml={'5%'}>
            <CustomToolTip text='Add a board to give this role permission to see it in the primary view'>
              <Select
                value={selectedBoard}
                placeholder='Choose Board'
                ml='5%'
                icon={<Chalkboard size={10} />}
                defaultValue={selectBoardOptions?.filter((b) => !JSON.stringify(form.values.viewBoards?.map(vb => vb.boardId))?.includes(b?.value))[0]?.value}
                data={selectBoardOptions?.filter((b: SelectItem) => !JSON.stringify(form.values.viewBoards?.map(vb => vb.boardId))?.includes(b?.value))}
                onChange={(value) => setSelectedBoard(value)}
              />
            </CustomToolTip>
            <Tooltip label='Adding a board to this list will give permission to view'><Button variant='outline' compact
              onClick={() => {
                selectedBoard && addBoard(selectedBoard);
                setSelectedBoard(null);
              }}
            >Add</Button></Tooltip>

            <Button
              size='xs'
              sx={{ '&:hover': { backgroundColor: 'transparent !important' } }}
              variant='subtle'
              onClick={() => setShowBoardAdvancedSettings(p => !p)}
              leftIcon={showBoardAdvancedSettings ? <SquareCheck /> : <Square />}>More Options</Button>
          </Group>
          {form.values.viewBoards?.length > 0 ? (
            <Group position='left' spacing={0}>
              <Table className='boardTable' align='center' ml='5%' mt='lg' withColumnBorders w={showBoardAdvancedSettings ? '90%' : '50%'}>
                <thead>
                  <tr>
                    <th>Remove</th>
                    <th>Board</th>
                    <th><CustomToolTip position='right' text='Modify permission is full permission unless further defined in "More Options"'>Can Modify</CustomToolTip></th>
                    {showBoardAdvancedSettings && (<>
                      <th >Create Magnets</th>
                      <th >Set Defaults</th>
                      <th >Load Defaults</th>
                      <th >Archive</th>
                      <th >Clear Board</th>
                    </>)}
                  </tr>
                </thead>
                <tbody>


                  {form.values.viewBoards.map((b, i) => {

                    return (
                      <tr key={b.boardId}>
                        <td>
                          <ActionIcon onClick={() => { removeBoard(i) }}>
                            <X />
                          </ActionIcon>
                        </td>
                        <td>{selectBoardOptions?.find(o => String(o?.value) === String(b?.boardId))?.label}</td>

                        <td>
                          <Checkbox
                            label=''
                            size='xs'

                            onClick={(e) => handleCanModifyClick(i, e.currentTarget.checked)}
                            {...form.getInputProps('viewBoards.' + i + '.canModify', { type: 'checkbox' })} />
                        </td>
                        {showBoardAdvancedSettings && (<>
                          <td > <Checkbox label='' name='canCreateMagnets' size='xs' {...form.getInputProps('viewBoards.' + i + '.canCreateMagnets', { type: 'checkbox' })} /></td>
                          <td > <Checkbox label='' name='canSetDefaults' size='xs' {...form.getInputProps('viewBoards.' + i + '.canSetDefaults', { type: 'checkbox' })} /></td>
                          <td > <Checkbox label='' name='canLoadDefaults' size='xs' {...form.getInputProps('viewBoards.' + i + '.canLoadDefaults', { type: 'checkbox' })} /></td>
                          <td > <Checkbox label='' name='canArchive' size='xs' {...form.getInputProps('viewBoards.' + i + '.canArchive', { type: 'checkbox' })} /></td>
                          <td > <Checkbox label='' name='canClearBoard' size='xs' {...form.getInputProps('viewBoards.' + i + '.canClearBoard', { type: 'checkbox' })} /></td>
                        </>)}
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Group>
          ) : (<Flex justify='center' mt='xl' >This Role cannot see any boards</Flex>)}

          <Group position="apart" mt="md">
            {id ? (<ActionIcon onClick={() => {
              // id && RoleStore.deleteRole(id)
              // navigate('/admin/roles', { replace: true })
              confirmDelete(id);
            }}>
              <Trash color={theme.colors.red[7]} /></ActionIcon>)
              : (<div></div>)}
            <Button type="submit">{id ? 'Save' : 'Create'}</Button>
          </Group>
        </form>
      </Paper>
    </Container>
  );
}
export default observer(RoleForm);