import { useState, useEffect } from 'react'
import { useLocation, Navigate, Outlet } from "react-router-dom";
import Unauthorized from './Unauthorized';
import { LoadingOverlay } from '@mantine/core';
import { AuthStore } from '../stores/authStore';
import { observer } from 'mobx-react';

const RequireAuth = () => {
    const location = useLocation();

    return (<>
        {AuthStore.allowedPages.filter(p => {
            return location.pathname.toLowerCase().startsWith(p)
        }).length ?
            <Outlet />
            : AuthStore.accessToken ?
                <Unauthorized />// <Navigate to="/unauthorized" state={{ from: location }} replace />
                :
                <Navigate to="/login" state={{ from: location }} replace />
        }
    </>);
}

export default observer(RequireAuth);
