import { API_URL } from './GlobalFetchSettings.js'
import { privateFetch as fetch } from '../../hooks/usePrivateFetch.js';
const BOARD_URL = API_URL + '/Boards';
const TEMPLATE_URL = API_URL + '/Template';
const MAGNET_URL = API_URL + '/Magnet';
const MAGNETGROUP_URL = API_URL + '/MagnetGroup';



//get request
export const GetMagnetGroups = async () => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const response = await fetch(MAGNETGROUP_URL, requestOptions);
        return response.json();
    }
    catch (e) {
        console.error("ERROR: " + e)
    }
};

export const GetMagnetGroupById = async (id, setData) => {
    fetch(MAGNETGROUP_URL + '/' + id)
        .then((res) => res.json()
            .then((data) => {
                setData(data);
            }))
        .catch(ex => console.error(ex));
}

//post request
export async function PostMagnetGroup(group) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(group)
    };
    try {
        const res = await fetch(MAGNETGROUP_URL, requestOptions)
        return res.json();
    }
    catch (ex) {
        console.error(ex);
    }
}

//put request
export async function PutMagnetGroup(group) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(group)
    };

    try {
        const res = await fetch(MAGNETGROUP_URL + '/' + group.id, requestOptions)
        return res.json();
    }
    catch (ex) {
        console.error(ex);
    }
}

//delete requeset
export async function DeleteMagnetGroup(groupId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    fetch(MAGNETGROUP_URL + '/' + groupId, requestOptions)
        .then((response) => {
            return response;
        });
}
