import { action, makeObservable, observable } from "mobx"
import { DeleteJob, GetArchiveJobs, NewArchiveJob } from "../../../exports/fetch/ArchiveSchedulerFetch";

export interface templateObject {
    id: number
    isActive: boolean
    showTemplateHeader: boolean
    templateHeader: string
    templateName: string
}

export interface ArchiveSchedulerTemplate {
    id?: number,
    template?: templateObject,
    templateId: number,
    interval: string,
    time: string
    //createdBy: string | undefined
};

export class ArchiveStoreClass {
    archiveJobs: ArchiveSchedulerTemplate[] = [];
    constructor() {
        makeObservable(this, {
            archiveJobs: observable,
            getJobs: action,
            addJob: action,
            deleteJob: action
        })
    }
    getJobs() {
        GetArchiveJobs().then((data) => {
            this.archiveJobs = data;
        })
    }
    addJob(job: ArchiveSchedulerTemplate) {
        NewArchiveJob(job).then((data) => {
            if (!data) return;
            this.archiveJobs.push(data);
        })
    }
    updateJob() {
    }

    deleteJob(id: any) {
        DeleteJob(id).then((success) => {
            if (!success) return;
            this.archiveJobs = this.archiveJobs.filter(r => r.id !== id);
        })
    }
}
export const SchedulerObj = new ArchiveStoreClass();