import React from 'react'
import { createContext, useState, useEffect, useRef } from "react";
import { GetPluginById, GetPlugins } from '../exports/fetch/PluginFetch';

const PluginContext = createContext({});

interface detailInterface {
    title: string,
    description: string,
    image: string
}

export interface PluginInterface {
    id: number,
    name: string,
    isActive: boolean,
    key: string
    details: detailInterface
}

export const PluginProvider = ({ children }: any) => {
    const [pluginList, setPluginList] = useState<PluginInterface[]>([]);
    const [loading, setLoading] = useState(true);
    const refreshInterval = useRef<any>(null);

    const getPlugins = async () => {
        const data = await GetPlugins();
        setPluginList(data);
        setLoading(false);
        return data;
    }

    const getPluginById = async (id: number) => {
        const data = await GetPluginById(id);
        setLoading(false);
        return data;
    }

    const stopPluginListRefresh = () => {
        clearInterval(refreshInterval.current);
    }

    const startPluginListRefresh = () => {
        setLoading(true);
        getPlugins();
        refreshInterval.current = setInterval(() => {
            getPlugins();
        }, 10000);
    }

    useEffect(() => {
        return () => clearInterval(refreshInterval.current);
    }, []);

    return (
        <>
            <PluginContext.Provider value={{ pluginList, startPluginListRefresh, stopPluginListRefresh, loading, getPlugins, getPluginById }}>
                {children}
            </PluginContext.Provider>
        </>
    )
}

export default PluginContext; 