import { API_URL, customErrorNotification } from './GlobalFetchSettings.js'
import { privateFetch as fetch } from '../../hooks/usePrivateFetch.js';
const BOARD_URL = API_URL + '/Boards';
const TEMPLATE_URL = API_URL + '/Template';
const MAGNET_URL = API_URL + '/Magnet';
const MAGNETGROUP_URL = API_URL + '/MagnetGroup';
const ROLE_URL = API_URL + '/Role'

const getToken = () => {
    return window.sessionStorage.getItem("mbtoken");
}

//get request
export const GetRoles = async () => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const response = await fetch(ROLE_URL, requestOptions);
        const data = await response.json();
        return data;
    }
    catch (e) {
        console.error(e)
    }
};

//post request
export async function PostRole(role) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(role)
    };
    try {
        const res = await fetch(ROLE_URL, requestOptions)
        return res.json();
    } catch (ex) {
        console.error(ex);
    }
}

//put request
export async function PutRole(role) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(role)
    };

    try {
        let res = await fetch(ROLE_URL + '/' + role.id, requestOptions)
        if (res.ok) return true;
        else if (res.status === 404) customErrorNotification(res, 'Could not find Role to update')
        else customErrorNotification(res);
        return false;
    } catch (err) {
        console.error(err);
        return false;
    }

}

//delete requeset
export async function DeleteRole(roleId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    try {
        let res = await fetch(ROLE_URL + '/' + roleId, requestOptions)
        if (res.ok) return true;
        else if (res.status === 404) customErrorNotification(res, 'Could not find Role to delete')
        else if (res.status === 401) customErrorNotification(res, 'You do not have permission to delete roles');
        else customErrorNotification(res);
        return false;
    } catch (err) {
        console.error(err);
        return false;
    }
}
