import { Button, Center, Container, Grid, LoadingOverlay, Space, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import MagnetFormADPLocked from "./MagnetFormADPLocked";
import { PluginStore } from "../../Plugins/PluginStore";
import { ArrowLeft, Magnet, MagnetOff } from "tabler-icons-react";
import TTCPluginDisplay from "./ADPFormTTCDisplayData";
import WMPluginDisplay from "./ADPFormWMDisplayData";
import { showNotification } from "@mantine/notifications";
import { GetADPWorkerByAOID, LinkPluginXMagnet, UnlinkPluginXMagnet } from "../../../../exports/fetch/PluginFetch";
import useMagnets from "../../../../contexts/useMagnets";

export const ADPFormDisplayData = ({ magnetData, workerData, form, isADPLinked, setIsADPLinked, setADPWorker, setShowADPWorkerForm }: any) => {
    const { getMagnets, magnetList }: any = useMagnets();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        !workerData && getWorkerData();
    }, [])

    const getWorkerData = async () => {
        setLoading(true);
        let workerAOID = magnetData?.pluginxmagnet?.unique_id
        const workerData = await GetADPWorkerByAOID(workerAOID);
        setADPWorker(workerData);
        setLoading(false);
    };

    const showPluginActivity = (plugin: boolean) => {
        return (<>{plugin ? <Text style={{ color: '#3bb143' }}>&nbsp;Active</Text> : <Text style={{ color: '#FF0000' }}>&nbsp;Inactive</Text>}</>)
    };

    const returnWorkerTable = () => {
        form.setFieldValue('linkNewMagnet', null);
        setADPWorker(null);
        setShowADPWorkerForm(false);
    };

    const linkMagnet = async (data: any) => {
        try {
            if (!isADPLinked && magnetData?.id) {
                const body = {
                    unique_id: workerData?.associateOID,
                    magnet_id: data?.id,
                    plugin_id: 1001,
                    clocked_in: false
                }
                let magnetLink = await LinkPluginXMagnet(body);
                if (magnetLink) {
                    //magnetData.pluginxmagnet = magnetLink;
                    await getMagnets();
                    showNotification({
                        title: 'Create',
                        message: `Magnet - ${magnetData?.magnetName} successfully linked to ${workerData?.legalName?.formattedName}`
                    });
                    setIsADPLinked(true);
                }
            }
            else {
                form.setFieldValue('linkNewMagnet', workerData?.associateOID);
                showNotification({
                    title: 'Pending ADP data link',
                    message: `Submit Magnet to save Magnet/ADP data linkage.`
                });
            }
        }
        catch (error) { console.error(error) }
    };

    const unLinkMagnet = async () => {
        try {
            if (isADPLinked) {
                let magnetUnlink = await UnlinkPluginXMagnet(magnetData?.id);
                if (magnetUnlink) {
                    await getMagnets();
                    //magnetData.pluginxmagnet = null;
                    showNotification({
                        title: 'Create',
                        message: `Magnet - ${magnetData?.magnetName} successfully unlinked from ADP data`
                    });
                    setIsADPLinked(false);
                }
            }
        }
        catch (error) { console.error(error) }
    };

    return (
        <>
            <Container>
                <Text size="lg" weight={700} style={{ borderBottom: '2px solid #007bff', display: 'flex' }}>
                    ADP Worker Management Plugin: {showPluginActivity(PluginStore.plugins[0]?.isActive || false)}
                </Text>

                {!PluginStore.plugins[0]?.isActive ? (<MagnetFormADPLocked />) :
                    <>
                        <LoadingOverlay visible={loading} />
                        <WMPluginDisplay isADPLinked={isADPLinked} workerData={workerData} form={form} />
                    </>
                }

                {isADPLinked ? (
                    <>
                        <Text size="lg" weight={700} style={{ paddingTop: '15px', borderBottom: '2px solid #007bff', display: 'flex' }}>
                            ADP Team Time Card Plugin:{showPluginActivity(PluginStore.plugins[1]?.isActive || false)}
                        </Text>

                        {PluginStore.plugins[1]?.isActive ? (
                            <TTCPluginDisplay magnetData={magnetData} />
                        ) : (
                            <MagnetFormADPLocked />
                        )}
                    </>
                ) : null}
            </Container>

            {!PluginStore.plugins[0]?.isActive ? null :
                !isADPLinked ?
                    <Grid style={{ margin: '5px', marginTop: '30px' }}>
                        <Grid.Col span={3} offset={2}>
                            <Button leftIcon={<ArrowLeft />} onClick={() => returnWorkerTable()}>
                                Worker List
                            </Button>
                        </Grid.Col>
                        <Grid.Col span={4} offset={2} >
                            <Button
                                leftIcon={<Magnet />}
                                onClick={() => linkMagnet(magnetData)}
                                disabled={form.values.linkNewMagnet ? true : false}
                            >
                                Link to Magnet
                            </Button>
                        </Grid.Col>
                    </Grid>
                    :
                    <Center style={{ paddingTop: '25px' }}>
                        <Button leftIcon={<MagnetOff />} color="red" variant="outline" onClick={() => unLinkMagnet()}>
                            Unlink ADP Data
                        </Button>
                    </Center>
            }
        </>
    )
};