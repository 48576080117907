import React from 'react';
import { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { Flex, Image, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { Stack3 } from 'tabler-icons-react';
import { TTC_Activity_Circle } from './VC-Plugins/ADPTTCClocked_in';
import { Magnet } from '../../models/Magnet';
import { encode } from 'base64-arraybuffer';
import MagnetForm from '../AdminConsole/MagnetDesigner/MagnetForm.js';
import { closeModal, openModal } from '@mantine/modals';

interface Coordinates {
    x: number,
    y: number
}
interface MagnetProps {
    data: Magnet,
    sourceLoc: string,
    coordinates: Coordinates,
    isDraggable?: Boolean,
    openState?: Boolean,
    setDrawerVisibility: (state: string) => void,
    setModalTempHidden: (state: Boolean) => void,
}



export function Magnet2023(props: MagnetProps) {
    const theme = useMantineTheme();
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'magnet',
        item: { ...props },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        })
    }));

    useEffect(() => {
        if (isDragging && props.openState !== undefined) props.setDrawerVisibility('hidden');
        else if (props.openState !== undefined) props.setDrawerVisibility('visible')
    }, [isDragging])


    function LightenDarkenColor(col: string, amt: number) {
        var usePound = true;
        if (col[0] === "#") {
            col = col.slice(1);
            usePound = true;
        }

        var num = parseInt(col, 16);

        var r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        var b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        var g = (num & 0x0000FF) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;
        var str = "000000" + (g | (b << 8) | (r << 16)).toString(16)
        return (usePound ? "#" : "") + str.substr(str.length - 6);
    }

    const magnetStyle = {
        cursor: isDragging ? 'grabbing' : 'grab',
        //cursor: 'grab',
        height: '100%',
        width: '100%',
        //minHeight: '100%',
        margin: 0,
        display: 'flex',
        //padding:'0',
        background: theme.fn.radialGradient(props.data.magnetColor, LightenDarkenColor(props.data.magnetColor.substr(1), -20)),
        justifyContent: 'center',
        borderRadius: '5px',
        boxShadow: '1px 1px 0px #F4AAB9',
        fontSize: '.7rem',
        color: props.data.textColor,
        fontWeight: 'bold',
        opacity: isDragging ? '.35' : '1',
    }
    const testStyle: React.CSSProperties = {
        cursor: isDragging ? 'grabbing' : 'grab',
        opacity: isDragging ? '.35' : '1',
        color: props.data.textColor,
        background: theme.fn.radialGradient(props.data.magnetColor, LightenDarkenColor(props.data.magnetColor.substr(1), -20)),
        fontWeight: 'bold',
        borderRadius: '5px',
        height: '100%',
        position: 'relative',
        fontSize: '.7rem',
    }

    const handleOnDrag = () => {
        if (props.setModalTempHidden !== undefined) {
            props.setModalTempHidden(true);
        }
    }

    const handleMagnetForm = () => {

        openModal({
            modalId: 'magnetForm',
            children: (
                <MagnetForm
                    groupData={props.data.magnetgroup}
                    magnetData={props.data}
                    onSubmit={closeModal}
                    templateId={undefined} />
            ),
            size: 'xl',
            closeOnClickOutside: false,
        })
    };

    const iconProps = {
        margin: 3,
        size: 10
    }
    return (
        <>
            <Flex
                style={testStyle}
                ref={drag}
                id={props.data.id}
                key={props.data.id}
                onDrag={() => handleOnDrag()}
                direction='row'
                wrap='wrap'
                align='center'
                justify='center'
            //onDoubleClick={() => handleMagnetForm()}
            >
                <Flex direction='row' gap='md' justify='center' align='center' pr={15} pl={15}>
                    {props.data.image_data && (<Image width={20} radius={20} src={`data:image/jpeg;base64,${encode(props.data?.image_data?.data)}`} />)}
                    <Text align='center'>
                        {props.data.magnetName.length >= 35 ? (
                            <Tooltip label={props.data.magnetName}><span>{props.data.magnetName.substring(0, 35)}...</span></Tooltip>
                        ) : props.data.magnetName}
                    </Text>
                </Flex>

                {/* top left icon */}
                {(props.data.magnetgroup?.canBeCloned && props.sourceLoc === 'pouch') && (
                    <>
                        {/* <Stack3 style={{ position: 'absolute', top: iconMargin, right: iconMargin }} size={iconSize} /> */}
                        <Stack3 style={{ position: 'absolute', top: iconProps.margin, left: iconProps.margin }} size={iconProps.size} />
                    </>
                )}
                {/* top right icon */}
                {(props.data.pluginxmagnet) && (
                    <>
                        {/* <Stack3 style={{ position: 'absolute', top: iconMargin, right: iconMargin }} size={iconSize} /> */}
                        {/* <Clock style={{ position: 'absolute', bottom: topLeftIcon.margin, right: topLeftIcon.margin }} size={topLeftIcon.size} /> */}
                        <TTC_Activity_Circle magnetData={props?.data} iconProps={iconProps} />
                    </>
                )}
            </Flex>

        </>
    );
}


// export function MagnetForDisplayOnly(props) {
//     const theme = useMantineTheme();
//     function LightenDarkenColor(col, amt) {
//         if (!col) return;
//         var usePound = true;
//         if (col[0] === "#") {
//             col = col.slice(1);
//             usePound = true;
//         }

//         var num = parseInt(col, 16);

//         var r = (num >> 16) + amt;

//         if (r > 255) r = 255;
//         else if (r < 0) r = 0;

//         var b = ((num >> 8) & 0x00FF) + amt;

//         if (b > 255) b = 255;
//         else if (b < 0) b = 0;

//         var g = (num & 0x0000FF) + amt;

//         if (g > 255) g = 255;
//         else if (g < 0) g = 0;
//         var str = "000000" + (g | (b << 8) | (r << 16)).toString(16)
//         return (usePound ? "#" : "") + str.substr(str.length - 6);
//     }

//     const magnetStyle = {
//         maxWidth: '200px',
//         minHeight: '30px',
//         background: theme.fn.radialGradient(props.data.magnetColor, LightenDarkenColor(props.data.magnetColor?.substr(1), -20)),
//         color: props.data.textColor ? props.data.textColor : '',
//         // padding: '0px',
//         whiteSpace: 'nowrap',
//         overflow: 'hidden',
//         textOverflow: 'ellipsis'
//     }

//     return (
//         <Button
//             style={magnetStyle}
//             size="md"
//             shadow="xs"
//             radius="md"
//             fullWidth
//         >
//             <Center>
//                 <div
//                     id={props.data.id}
//                     key={props.data.id}
//                 >
//                     {props.data.magnetName ? (props.data.magnetName) : props.defaultText ? props.defaultText : 'Sample Text'}
//                 </div>
//             </Center>
//             <TTC_Activity_Circle magnetData={props?.data} size={20} />
//         </Button>

//     );
// }