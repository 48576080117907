import { Container, Title, LoadingOverlay, Text, Space, Center } from '@mantine/core';
import { useEffect } from 'react'
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import NewUserForm from './NewUserForm';
import useSettings from '../../../contexts/contextHooks/useSettings';
import { RoleStore } from '../RoleSettings/RoleStore';
import useUsers from '../../../contexts/useUsers';
import { userClassObj } from './UserStore';
import { observer } from 'mobx-react-lite';
import { customErrorNotification } from '../../../exports/fetch/GlobalFetchSettings';
import { AuthStore } from '../../../stores/authStore';
import { TableSort } from '../../Utilities/TableSort';

function UserList() {
    const { loading, startUserListRefresh, stopUserListRefresh } = useUsers();
    const { fullNavbar } = useSettings();

    useEffect(() => {
        startUserListRefresh();
        return () => stopUserListRefresh();
    }, [])

    const handleUserForm = (userId) => {
        if (Number(userId) === 1 && AuthStore.username !== 'master') customErrorNotification('Error', "You must be logged in on Master account to make changes");
        else {
            openModal({
                modalId: 'userForm',
                children: (
                    <NewUserForm
                        userId={userId ? Number(userId) : undefined}
                        onSubmit={closeModal}
                        confirmDelete={confirmDelete}
                    />
                ),
                size: 'md',
                closeOnClickOutside: false,
            })
        }
    };

    const confirmDelete = (userId, username) => openConfirmModal({
        title: (<Center> Deleting User:<Space w="xs" /><Text weight="bold"> {username}</Text></Center>),
        children: (<Text align='center'>Are you sure you wish to continue?</Text>),
        labels: { confirm: 'Yes - Delete', cancel: 'Cancel' },
        confirmProps: { color: 'red' },
        onConfirm: () => {
            userClassObj.deleteUser(userId);
            closeModal && closeModal('userForm');
        }
    })

    useEffect(() => {
        RoleStore.getRoles();
        userClassObj.getUsers()
    }, []);

    return (
        <>
            {userClassObj && (
                <Container size="md" pr={{ xl: fullNavbar ? '300px' : null }} style={{
                    marginTop: '5%'
                }}>
                    <LoadingOverlay visible={loading} />
                    <Title align="center" order={3}>Users</Title>
                    {(userClassObj) && (
                        <TableSort
                            newButtonText='New User'
                            handleClickNew={() => handleUserForm(null)}
                            handleClickItem={(userId) => handleUserForm(userId)}
                            data={userClassObj.users.map(user => {
                                return {
                                    id: String(user.id),
                                    "Username": user.username,
                                    "Access Level": user.role_id ? RoleStore.roles.filter(r => r.id === user.role_id)[0]?.name : "<No Access>"
                                }
                            })}
                        />
                    )}
                </Container>
            )}

        </>
    )
}

export default observer(UserList);