import { Center, Container, LoadingOverlay, Space, Text, Title } from '@mantine/core';
import React, { useEffect, useState } from 'react'
import { ArchiveSchedulerTemplate, SchedulerObj } from './ArchiveSchedulerStore';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import ArchiveSchedulerForm from './ArchiveSchedulerForm';
import useArchiveScheduler from '../../../contexts/useArchiveScheduler';
import { GetTemplates } from '../../../exports/fetch/TemplateFetch';
import { Trash } from 'tabler-icons-react';
import { observer } from 'mobx-react';
import { TableSort } from '../../Utilities/TableSort';

const ArchiveSchedulerList = () => {
    const { loading, startArchiveListRefresh, stopArchiveListRefresh }: any = useArchiveScheduler();
    const maxJobs = 5;

    const confirmDelete = (value: ArchiveSchedulerTemplate) => openConfirmModal({
        title: (<Center> Deleting Archive Job: {value?.template?.templateName + ' - ' + value.interval}<Space w="xs" /><Text weight="bold">
            <Space w="xs" /></Text></Center>),
        children: (<Text align='center'>Are you sure you wish to continue?</Text>),
        labels: { confirm: 'Yes - Delete', cancel: 'Cancel' },
        confirmProps: { color: 'red' },
        onConfirm: () => {
            SchedulerObj.deleteJob(value.id);
        }
    })

    const handleSchedulerForm = async () => {
        const templateListData = await GetTemplates();
        openModal({
            modalId: 'archiveSchedulerForm',
            title: 'Schedule Archive',
            children: (
                <ArchiveSchedulerForm
                    SchedulerObj={SchedulerObj}
                    templateListData={templateListData}
                    closeModal={closeModal}
                    onSubmit={closeModal}
                />
            )
        })
    }

    const handleEditJob = (id: number) => {

    }

    const jobsRemaining = () => { return (<p>Jobs Remaining: {maxJobs - SchedulerObj.archiveJobs.length}</p>) }

    const tableSortProps = {
        postsPerPage: 8,
        disableButton: maxJobs - SchedulerObj.archiveJobs.length <= 0,
        changeSearchBar: jobsRemaining(),
        newButtonText: 'Schedule Archive',
        data: SchedulerObj?.archiveJobs?.map((r) => {
            return {
                id: String(r.id),
                "Board": String(r?.template?.templateName),
                //"Created By": String(r.createdBy),
                "Archive Time": `${r.interval} - ${new Date((r.time)).toLocaleString([], { timeStyle: 'short' })}`,
                "Delete": {
                    view: (<Trash key={'delete' + r.id} style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => confirmDelete(r)} />)
                }
            }
        }),
        handleClickNew: handleSchedulerForm,
        handleClickItem: handleEditJob,
    }

    useEffect(() => {
        SchedulerObj.getJobs();
    }, []);

    useEffect(() => {
        startArchiveListRefresh();
        return () => stopArchiveListRefresh();
    }, [])

    return (
        <>
            <LoadingOverlay visible={loading} />
            <Container size="md" pr={{ xl: '300px' }} style={{
                marginTop: '8vh'
            }}>
                {/* <LoadingOverlay visible={loading} /> */}
                <Title align="center" order={3}>Archive Jobs</Title>
                {(SchedulerObj?.archiveJobs) && (
                    <TableSort
                        {...tableSortProps}
                    />
                )}
            </Container>

        </>
    )
}
export default observer(ArchiveSchedulerList)