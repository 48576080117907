import { cleanNotifications, showNotification } from "@mantine/notifications";

const LOCAL = false; //set to false if you want to force use the cloud api

//console.log('Is localhost: ' + window.location.href.includes('localhost'));

export let API_URL = '';

//Node.js: 5000 or 3000, ASP.NET 7207
if (LOCAL && window.location.href.includes('localhost')) API_URL = 'http://localhost:5000/api'; //DEV LOCAL
else if (!LOCAL && window.location.href.includes('localhost')) API_URL = 'https://api.dev.magniboard.com/api'; //DEV CLOUD
else if (window.location.href.includes('dev')) API_URL = 'https://api.dev.magniboard.com/api'; //DEV FRONT AND BACK CLOUD
else API_URL = 'https://api.magniboard.com/api';//PROD
// else API_URL = 'https://magniboardbackend20220713222856.azurewebsites.net/api';

export const isValid = (res) => {
    if (res.status >= 200 && res.status < 300) {
        return true;
    }
    else return false;
}

export const customErrorNotification = (res, customMsg) => {
    cleanNotifications();
    showNotification({
        title: 'Error',
        message: customMsg ? customMsg : res.status + ': ' + res.statusText,
        color: 'red'
    })
}