import { API_URL } from '../exports/fetch/GlobalFetchSettings';
import { AuthStore } from '../stores/authStore';

export const refresh = async () => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    };
    const response = await fetch(API_URL + '/Refresh', requestOptions);
    if (response.status === 403) {
        AuthStore.logout().then(() => {
            window.location.replace(window.location.origin);
        });
    }
    const data = await response.json();
    AuthStore.setAuth(data.user.username, data.user.role, data.accessToken);
    return data;
}

export const privateFetch = async (...args) => {
    let [resource, requestOptions] = args;
    const authRequestOptions = {
        ...requestOptions,
        headers: {
            ...requestOptions.headers,
            Authorization: 'Bearer ' + AuthStore.accessToken
        }
    }
    let response = await fetch(resource, authRequestOptions)
    if (response.status === 403) {
        const data = await refresh();
        const newRequestOptions = {
            ...requestOptions,
            headers: {
                ...requestOptions.headers,
                Authorization: 'Bearer ' + data.accessToken
            }
        }
        response = await fetch(resource, newRequestOptions);
        if (response.status === 403) {
            AuthStore.logout().then(() => {
                window.location.replace(window.location.origin);
            });
        }
    }
    //if(response.refreshRole) AuthStore.refreshRole();

    return response;
}

