import { API_URL, customErrorNotification } from './GlobalFetchSettings'
import { privateFetch as fetch } from '../../hooks/usePrivateFetch';
import { ArchiveTemplate } from '../../Components/AdminConsole/ArchiveLogs/ArchiveStore';

const ARCHIVE_URL = API_URL + '/Archive';

export const GetRecordById = async (id: number) => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', }
        }
        const res = await fetch(ARCHIVE_URL + '/GetRecord/' + id, requestOptions)
        return await res.json();
    } catch (error) {
        console.error(error);
    }
};

export async function GetRecords() {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const res = await fetch(ARCHIVE_URL, requestOptions)
        return await res.json();
    } catch (e) {
        console.error(e)
    }
}

export async function GetLimitedRecords(page: number) {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const res = await fetch(ARCHIVE_URL + `/GetRecords?page=${page}`, requestOptions)
        if (res.ok) return res.json();
        else {
            customErrorNotification(res);
            return false;
        }
    } catch (e) {
        console.error(e)
    }
}

export async function newArchiveRecord(template: ArchiveTemplate) {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(template)
        };
        let res = await fetch(ARCHIVE_URL, requestOptions)
        if (res.ok) return res;
        else {
            customErrorNotification(res);
            return false;
        }
    } catch (e) {
        console.error(e)
    }
}

export async function adminCreateRecord(archiveData: any) {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(archiveData)
        };
        let res = await fetch(ARCHIVE_URL + '/CreateAdminRecord', requestOptions)
        if (!res.ok) {
            customErrorNotification(res);
            return false;
        }
        return res.json();
    } catch (e) {
        console.error(e)
    }
}


export async function deleteArchiveRecord(id: number) {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        let res = await fetch(ARCHIVE_URL + '/' + id, requestOptions)
        if (res.ok) return true;
        else if (res.status === 404) customErrorNotification(res, 'Could not find Archive to delete')
        else customErrorNotification(res);
        return false;
    } catch (e) {
        console.error(e)
    }
}