import { useEffect } from 'react';
import { Title, TextInput, Stack, Group, Button, ColorInput, Container, Checkbox, Divider, Space, Center, Text, Tooltip, UnstyledButton } from '@mantine/core';
import { useForm } from '@mantine/form';
import { MagnetForDisplayOnly } from '../../ViewComponents/MagnetComponent';
import useMagnetGroups from '../../../contexts/useMagnetGroups';
import MagnetList from '../MagnetDesigner/MagnetList';
import { DeviceFloppy, InfoCircle, Lock, Plus, Trash } from 'tabler-icons-react';
import { closeAllModals, openConfirmModal } from '@mantine/modals';

const MagnetGroupForm = ({ groupData, setShowForm, onSubmit }) => {

  const colorSwatches = ['#000000', '#ffffff', '#ffd580', '#25262b', '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14'];
  const { createMagnetGroup, updateMagnetGroup, deleteMagnetGroup } = useMagnetGroups();

  const form = useForm({
    initialValues: {
      id: groupData ? groupData.id : null,
      name: groupData ? groupData.name : '',
      defaultMagnetColor: groupData ? groupData.defaultMagnetColor : colorSwatches[4],
      defaultTextColor: groupData ? groupData.defaultTextColor : colorSwatches[0],
      magnets: groupData ? groupData.magnets : [],
      canBeCloned: groupData ? groupData.canBeCloned : false
    }
  });

  useEffect(() => {
  }, [])


  let handleSubmit = async (group) => {
    groupData ? updateMagnetGroup(group) : createMagnetGroup(group);
    setShowForm && setShowForm(false);
    onSubmit && onSubmit();
  };

  const confirmDelete = (group) => openConfirmModal({
    title: (<Center> Deleting Magnet:<Space w="xs" /><Text weight="bold"> {group?.name}</Text></Center>),
    children: (<Text align='center'>Are you sure you wish to continue?</Text>),
    labels: { confirm: 'Yes - Delete', cancel: 'Cancel' },
    confirmProps: { color: 'red' },
    onConfirm: () => {
      deleteMagnetGroup(group)
      closeAllModals();
    }
  })

  return (
    <>
      {groupData && (
        <Group
          position='left'
          style={{ position: 'absolute', top: '20px', left: '5px' }}
        >
          {groupData?.id > 999 ? (<Trash size={20} style={{ cursor: 'pointer' }} color='red' onClick={() => confirmDelete(groupData)} />) :
            (<Tooltip label="System Default - Cannot Delete"><UnstyledButton><Lock size={20} color='gray' /></UnstyledButton></Tooltip>)}
        </Group>
      )}
      <Container size="lg">
        {/* <Stack> */}
        <Title order={3} align="center">{groupData ? "Edit" : "New "} MagniGroup</Title>
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Group position='center' p='md' grow>
            <TextInput size="md"
              disabled={groupData?.id < 999}
              required
              label="MagniGroup Name"
              placeholder="name"
              {...form.getInputProps('name')}
            />
          </Group>
          <Group position='apart' p='xl'>
            <Stack>
              <ColorInput
                format="hex"
                swatches={colorSwatches}
                label="Default Text Color"
                {...form.getInputProps('defaultTextColor')}
              />
              <ColorInput
                format="hex"
                swatches={colorSwatches}
                label="Default Magnet Color"
                {...form.getInputProps('defaultMagnetColor')}
              />
            </Stack>
            <MagnetForDisplayOnly defaultText='<Sample>' data={{ magnetColor: form.values.defaultMagnetColor, textColor: form.values.defaultTextColor }} />
            <Group>
              <Checkbox
                disabled={(groupData?.canBeCloned || groupData?.id === 1)}
                label="Magnets Can Be Cloned"
                checked={form.values.canBeCloned}
                {...form.getInputProps('canBeCloned')}
              />
              <Tooltip label="Magnets will always exist in the pouch, even after dropped on the board so that multiple can be used."><UnstyledButton><InfoCircle size={15} strokeWidth={1} /></UnstyledButton></Tooltip>
            </Group>
          </Group>

          <Group position="right" mt="lg">
            <Button variant='light' type="submit" leftIcon={groupData ? (<DeviceFloppy />) : (<Plus />)}>{groupData ? "Save" : "Create"} Group</Button>
          </Group>
        </form>
        {
          groupData && (
            <>
              <br />
              <Divider variant='dotted' />
              <MagnetList magnetGroup={`<${groupData.name}> Magnets`} groupData={groupData ? groupData : null} />
            </>
          )}

        {/* </Stack> */}
      </Container>
    </>
  );
}

export default MagnetGroupForm
