import { useEffect, useRef, useState } from 'react';
import { Title, Paper, Stack, Button, Container, LoadingOverlay, Text, Center, Tabs, } from '@mantine/core';
import { useForm } from '@mantine/form';
import useMagnets from '../../../contexts/useMagnets';
import useMagnetGroups from '../../../contexts/useMagnetGroups';
import { AlertTriangle, Link, LinkOff, Magnet } from 'tabler-icons-react';
import MagnetFormADP from './ADPMagnet/MagnetFormADP';
import { MagnetGenericTemplate } from './MagnetGenericTemplate';

const MagnetForm = ({ groupData, magnetData, onSubmit, templateId }) => {
  const colorSwatches = ['#000000', '#ffffff', '#ffd580', '#25262b', '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14'];
  const { updateMagnet, createMagnet, createMagnetFromBoard } = useMagnets();
  const { magnetGroupList, getMagnetGroups, loading } = useMagnetGroups();
  const [errMsg, setErrMsg] = useState('');
  const [formOption, setFormOption] = useState('Magnet');

  const errRef = useRef();

  const form = useForm({
    initialValues: {
      id: magnetData ? magnetData.id : null,
      magnetName: magnetData ? magnetData.magnetName : '',
      magnetColor: magnetData ? magnetData.magnetColor : groupData ? groupData.defaultMagnetColor : colorSwatches[4],
      textColor: magnetData ? magnetData.textColor : groupData ? groupData.defaultTextColor : colorSwatches[0],
      magnetGroupId: magnetData ? magnetData.magnetGroupId : groupData ? groupData?.id : 1,
      image_data: magnetData ? magnetData?.image_data : null, //arrayBufferToBase64(magnetData?.image_data?.data) : null,
      custom_object: magnetData ? magnetData?.custom_object : null,
      linkNewMagnet: null
    }
  });

  useEffect(() => {
    getMagnetGroups();
  }, []);

  const handleUpdateMagnet = (formMagnet) => {
    formMagnet.pluginxmagnet = magnetData.pluginxmagnet;
    updateMagnet(formMagnet)
  };

  let handleSubmit = async (magnet) => {
    if (magnet.magnetName.length > 60) setErrMsg("Magnet Text length cannot exceed 60 characters!");
    else {
      magnetData ?
        handleUpdateMagnet(magnet)
        : templateId
          ? createMagnetFromBoard(magnet, templateId) : createMagnet(magnet);
      onSubmit && onSubmit('magnetForm');
    }
  };

  return (<>
    {!loading && (
      <Container size="sm">
        <Paper shadow="xl" p="lg">
          <LoadingOverlay visible={loading} />
          <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive" style={{ color: 'red' }}>
            {errMsg}
          </p>
          <Stack>
            <Title order={3} align="center">Magnet Designer</Title>
            {(form.isDirty('magnetGroupId') && !!magnetData) && (<>
              <Text size='xs' color='red'>
                <AlertTriangle color='red' /> Warning: Modifying the MagniGroup will remove the magnet from any boards not associated with the new MagniGroup!
                If previous group had the clone capability, then the magnet will be removed from all.
              </Text>
            </>)}
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              <Tabs defaultValue="Magnet" value={formOption} onTabChange={setFormOption}>
                <Tabs.List>
                  <Tabs.Tab value="Magnet" icon={<Magnet />}>Magnet</Tabs.Tab>
                  <Tabs.Tab value="ADP" icon={form.values.linkNewMagnet || magnetData?.pluginxmagnet ? <Link /> : <LinkOff />} >ADP Settings</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="Magnet" pt="xs">
                  <MagnetGenericTemplate form={form} colorSwatches={colorSwatches} magnetGroupList={magnetGroupList} />
                  <Paper style={{ paddingTop: '15tpx' }}>
                    <Center style={{ paddingTop: '30px' }}>
                      <Button type="submit">Submit</Button>
                    </Center>
                  </Paper>
                </Tabs.Panel>
                <Tabs.Panel value="ADP" pt="xs">
                  <MagnetFormADP magnetData={magnetData} form={form} />
                </Tabs.Panel>
              </Tabs>
            </form>
          </Stack>
        </Paper>
      </Container >)
    }
  </>
  );
}

export default MagnetForm
