import { useState, useEffect } from 'react';
import {
    AppShell,
    Footer,
    useMantineTheme,
    Image,
    MediaQuery,
    Aside,
    Text,
    Center
} from '@mantine/core';
import { AdminNavbar } from '../Components/AdminConsole/AdminNavbar';
import { Outlet, useNavigate } from 'react-router-dom';
import { ArchiveProvider } from '../contexts/ArchiveContext';
import { TemplateProvider } from '../contexts/TemplateContext';
import { TemplateDesignerProvider } from '../contexts/TemplateDesignerContext';
import { useIdle } from '@mantine/hooks';
import useAuth from '../contexts/contextHooks/useAuth';
import AdminHeader from './AdminHeader';
import { closeAllModals, closeModal, openModal } from '@mantine/modals';
import useSettings from '../contexts/contextHooks/useSettings';
import { Container } from 'tabler-icons-react';
import { ArchiveSchedulerProvider } from '../contexts/ArchiveSchedulerContext';
import { AuthStore } from '../stores/authStore'
import { UserProvider } from '../contexts/UserContext';

export default function AdminPageLayout() {
    const theme = useMantineTheme();
    const [opened, setOpened] = useState(false);
    const { getUser, logout } = useAuth();
    const { fullNavbar } = useSettings();
    const navigate = useNavigate();

    //logout timer
    const logoutIdle = useIdle(1000 * 60 * 15, { initialState: false });
    const preLogoutIdle = useIdle(1000 * 60 * 14, { initialState: false });
    useEffect(() => {
        if (preLogoutIdle) {
            openModal({
                modalId: 'logoutwarning',
                title: 'Idle Timeout',
                children: 'You will be logged out in 1 minute. Move your mouse to stay logged in.'
            })
        }
        else if (!preLogoutIdle) {
            closeModal('logoutwarning');
        }

        if (logoutIdle) {
            closeAllModals();
            AuthStore.logout().then(() => {
                navigate('/login');
            });

        }
    }, [logoutIdle, preLogoutIdle])

    return (
        <ArchiveProvider>
            <UserProvider>
                <ArchiveSchedulerProvider>
                    <TemplateProvider>
                        <TemplateDesignerProvider>
                            <AppShell
                                styles={{
                                    main: {
                                        background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                                    },
                                }}
                                navbarOffsetBreakpoint="sm"
                                //asideOffsetBreakpoint="sm"
                                navbar={
                                    <div
                                        // style={fullNavbar ? { visibility: 'visible' } : { visibility: 'hidden' }}
                                        style={fullNavbar ? null : { display: 'none' }}
                                    >
                                        <AdminNavbar p="md" hiddenBreakpoint="xs" hidden={!opened} width={{ sm: 200, lg: 300 }} />
                                    </div>
                                }

                                // footer={
                                //     <Footer height={60} p="md">
                                //         Application footer
                                //     </Footer>
                                // }
                                // aside={
                                //     <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                                //         <Aside p="md" hiddenBreakpoint="sm" width={{ sm: 200, lg: 300 }}>
                                //             <Text>Application sidebar</Text>
                                //         </Aside>
                                //     </MediaQuery>
                                // }
                                header={
                                    <AdminHeader logout={logout} />
                                }
                            >
                                <Outlet />
                            </AppShell>
                        </TemplateDesignerProvider>
                    </TemplateProvider >
                </ArchiveSchedulerProvider>
            </UserProvider>
        </ArchiveProvider >
    );
}