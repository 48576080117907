import { Button, Menu, Space, Title, useMantineTheme } from '@mantine/core';
import { ChevronRightIcon } from '@primer/octicons-react';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, CaretDown, Check, Logout, Moon, Sun } from 'tabler-icons-react';
import useSettings from '../../contexts/contextHooks/useSettings';
import { AuthStore } from '../../stores/authStore';

const AdminHeaderMenu = () => {
  const theme = useMantineTheme();
  const { getAppearance, setAppearance } = useSettings();
  const [mainMenu, setMainMenu] = useState(true)
  const [themeMenu, setThemeMenu] = useState(false);
  const navigate = useNavigate();

  const intialState = () => {
    setMainMenu(true);
    setThemeMenu(false);
  }

  const toggleThemeMenu = () => {
    setMainMenu((prev) => !prev);
    setThemeMenu((prev) => !prev);
  }

  return (
    <Menu
      onClose={() => intialState()}
      closeOnItemClick={false}
      width={'275px'}
      position='bottom-start'
      style={{ marginLeft: 'auto' }}
    >
      <Menu.Target>
        <Button color={theme.colorScheme === 'dark' && 'dark.6'} compact>
          <Title order={3} size='xs'>
            Welcome, {AuthStore.username}! <CaretDown size={12} />
          </Title>
        </Button>
      </Menu.Target>
      {
        mainMenu ?
          <Menu.Dropdown style={{ zIndex: 2 }}>
            <Menu.Item
              //closeMenuOnClick={false}
              icon={getAppearance().icon}
              rightSection={<ChevronRightIcon size={23} />}
              onClick={() => {
                toggleThemeMenu();
              }}
            >
              Appearance: {getAppearance().label}
            </Menu.Item>
            <Menu.Item size={14} icon={<Logout style={{ cursor: 'pointer' }} />} onClick={() => {
              AuthStore.logout();
              navigate('/login');
            }}>
              Sign out
            </Menu.Item>
          </Menu.Dropdown>
          :
          null
      }
      {
        themeMenu && !mainMenu ?
          <Menu.Dropdown>
            <Menu.Item
              icon={<ArrowLeft />}
              style={{}}
              onClick={() => {
                toggleThemeMenu();
              }}
            >
              Appearance
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              icon={<Sun />}
              rightSection={getAppearance().label === 'Light theme' ? <Check /> : null}
              onClick={() => setAppearance("Light theme")}
            >
              Light theme
            </Menu.Item>
            <Menu.Item
              icon={<Moon />}
              rightSection={getAppearance().label === 'Dark theme' ? <Check /> : null}
              onClick={() => setAppearance("Dark theme")}
            >
              Dark theme
            </Menu.Item>
            {/* <Menu.Item
                                        icon={<Coffee />}
                                        rightSection={getAppearance().label === 'Java theme' ? <Check /> : null}
                                        onClick={() => setAppearance("Java theme")}
                                    >
                                        Java theme
                                    </Menu.Item>
                                    <Menu.Item
                                        icon={<Leaf />}
                                        rightSection={getAppearance().label === 'Spearmint theme' ? <Check /> : null}
                                        onClick={() => setAppearance("Spearmint theme")}
                                    >
                                        Spearmint theme
                                    </Menu.Item>
                                    <Menu.Item
                                        icon={<Palette />}
                                        rightSection={getAppearance().label === 'Pastel theme' ? <Check /> : null}
                                        onClick={() => setAppearance("Pastel theme")}
                                    >
                                        Pastel theme
                                    </Menu.Item> */}
          </Menu.Dropdown>
          :
          null
      }
    </Menu>

  )
}

export default AdminHeaderMenu
