import { Center, CloseButton, Group, Space, Table, Text, Title } from '@mantine/core'
import { openConfirmModal } from '@mantine/modals';
import React, { useState } from 'react'
import { Trash } from 'tabler-icons-react';
import ArchiveMagnet from './ArchiveMagnet';
import { ArchiveObj } from './ArchiveStore';

const ArchiveForm = (props) => {
    const [board] = useState(props?.targetArchive?.templateObject);

    const confirmDelete = async () => openConfirmModal({
        title: (<Center> Deleting Archive:<Space w="xs" /><Text weight="bold"> {props.targetArchive?.templateObject?.templateName}
            <Space w="xs" />{new Date(props.targetArchive?.timestamp).toLocaleString()}</Text></Center>),
        children: (<Text align='center'>Are you sure you wish to continue?</Text>),
        labels: { confirm: 'Yes - Delete', cancel: 'Cancel' },
        confirmProps: { color: 'red' },
        onConfirm: async () => {
            await ArchiveObj.deleteArchive(props.targetArchive?.id);
            props.setShowForm(false);
        }
    })

    const rowHeight = String(80 / board?.rows?.length) < 7 ? String(80 / board.rows.length) + 'vh' : '7vh'

    return (
        <>
            <div style={{ width: '100%' }}>
                <Group position='apart'>
                    <Trash color='red' onClick={() => confirmDelete()} style={{ cursor: 'pointer', marginLeft: '12px' }} />
                    <h2>{props.targetArchive?.templateObject?.templateName} - {new Date(props.targetArchive?.timestamp).toLocaleString()}</h2>
                    <CloseButton size='xl' onClick={() => props.setShowForm(false)} />
                </Group>
                <Table
                    sx={(theme) => ({
                        [theme.fn.smallerThan('sm')]: { minWidth: '90%' },
                    })}
                    style={{
                        maxWidth: board.rows[0].cells.length * 10 > 98 ? '98%' : String(board.rows[0].cells.length * 10) + '%',
                        minWidth: '60%',
                        margin: 'auto',
                        padding: '10px',
                        border: '1px solid black',
                        fontSize: '10px',
                    }}>
                    {
                        board.showTemplateHeader && (
                            <thead><tr><th style={{ border: '1px solid black', textAlign: 'center' }} colSpan={board.rows[0].cells.length}><Title order={5}>{board.templateHeader}</Title></th></tr></thead>
                        )
                    }
                    <tbody>
                        {
                            board.rows.map((row, yIndex) => {
                                return (<tr cols={row.cells.length} key={yIndex} spacing={0}

                                    style={{ height: rowHeight }}>
                                    {row.cells.map((cell, xIndex) => {
                                        const CellTag = cell.header ? `th` : `td`;

                                        if (cell.colSpan > 0)
                                            return (
                                                <CellTag
                                                    colSpan={cell.colSpan}
                                                    key={xIndex}
                                                    style={{
                                                        //backgroundColor: cell.droppable && 'Cyan',
                                                        fontWeight: cell.header && 'bold',
                                                        border: '1px solid black',
                                                        height: 'calc(' + rowHeight + ')',
                                                        padding: 1,
                                                        width: String(100 / row.cells.length) + '%'
                                                    }}
                                                >
                                                    {
                                                        cell.magnet ?
                                                            (
                                                                <ArchiveMagnet setModalOpened={() => { }} sourceLoc="board" key={xIndex + ',' + yIndex} coordinates={{ x: xIndex, y: yIndex }} data={cell.magnet} />
                                                            )
                                                            :
                                                            cell.text && cell.text
                                                    }
                                                </CellTag>
                                            )
                                        else return null;
                                    })}

                                </tr>)
                            })}
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default ArchiveForm
