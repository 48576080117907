import PouchComponent from './PouchComponent';
import TemplateComponent from "./TemplateComponent";
import { useState, useEffect } from 'react';
import { Footer, Button, Group, Title, Text, Center } from '@mantine/core';
import { getActiveBoardWithPouchById, loadTemplate, PutCell, ResetBoard, saveTemplate, UpdateCells } from '../../exports/fetch/TemplateFetch';
import MagnetSideBar from './MagnetSideBar';
import { AuthStore } from '../../stores/authStore';
import { newArchiveRecord } from '../../exports/fetch/ArchiveFetch';
import { showNotification, updateNotification } from '@mantine/notifications';
import { closeAllModals, openConfirmModal, openModal } from '@mantine/modals';
import { SquarePlus } from 'tabler-icons-react/dist';
import MagnetForm from '../AdminConsole/MagnetDesigner/MagnetForm';
import { ScreenFullIcon, ScreenNormalIcon } from '@primer/octicons-react';
import { useFullscreen } from '@mantine/hooks';
import { useDragDropManager } from 'react-dnd';
import { useNavigate } from 'react-router';

function Board(props) {
    const dragDropManager = useDragDropManager();
    const monitor = dragDropManager.getMonitor();
    const { toggle, fullscreen } = useFullscreen();

    const [magnets, setMagnets] = useState(props.viewData.magnetPouch);
    const [board, setBoard] = useState(props.viewData.template);
    const [magnetGroups, setMagnetGroups] = useState(props.viewData.magnetGroups)
    const [archiveDisabled, setArchiveDisabled] = useState(false);

    const [counter, setCounter] = useState(0);
    const [startCount, setStartCount] = useState(false);

    const [sideBarOpen, setSideBarOpen] = useState(false)
    const [drawerVisibility, setDrawerVisibility] = useState('visible');

    const [wordEntered, setWordEntered] = useState('');
    const [sidebarTempList, setSidebarTempList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getBoardsAsync = async () => {
            let data = await getActiveBoardWithPouchById(board.id);
            if (data == 404) {
                navigate('/boards');
                props.setActiveTemplates([]);
                props.setActiveTab("default");
                return false;
            }
            return data;
        };

        if (board.id) {
            getBoardsAsync()
        };

        const interval = setInterval(async () => {
            if (!monitor.isDragging()) {
                const data = await getBoardsAsync();
                if (data) {
                    setMagnets(data.magnetPouch);
                    setBoard(data.template);
                    setMagnetGroups(data.magnetGroups);
                }
            }
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    const sortMagnetToPouch = (magnet) => {
        setMagnets((prevList) => {
            let newList = prevList.filter(item => item.id !== magnet.id)
            return { ...newList, magnet }
        });
    }

    const moveMagnet = (coordinates, magnet) => {
        let source = magnet.sourceLoc;
        let dest = magnet.destinationLoc;

        if (source === 'pouch' && dest === 'pouch') {
            return;
        }
        else if (source === 'board' && dest === 'board') {
            removeMagnetFromTemplate(magnet);
            addMagnetToTemplate(coordinates, magnet)
        }
        else if (source === 'pouch' && dest === 'board') {
            removeMagnetFromPouch(magnet)
            addMagnetToTemplate(coordinates, magnet)
        }
        else if (source === 'board' && dest === 'pouch') {
            removeMagnetFromTemplate(magnet);
            addMagnetToPouch(magnet)
        }

        setWordEntered('');
        setSidebarTempList([]);
        setDrawerVisibility('visible');
    }
    const addMagnetToTemplate = (coordinates, magnet) => {
        setBoard(prevBoard => {
            if (!magnet.data.magnetgroup?.canBeCloned) {
                let isMagnetExistOnBoard = JSON.stringify(prevBoard).includes(JSON.stringify(magnet.data))

                if (isMagnetExistOnBoard) {
                    showNotification({
                        title: 'Error',
                        message: 'Magnet "' + magnet.data.magnetName + '" already exists on board',
                        color: 'red'
                    })
                    return prevBoard;
                }
            }
            return {
                ...prevBoard, rows: prevBoard.rows.map((row, yIndex) => {
                    return {
                        ...row, cells: row.cells.map((cell, xIndex) => {
                            if (xIndex === coordinates.x && yIndex === coordinates.y) {
                                PutCell({ ...cell, magnetId: magnet.data.id, magnet: magnet.data }, board.id);
                                return { ...cell, magnetId: magnet.data.id, magnet: magnet.data };
                            }
                            else
                                return cell
                        })
                    }
                })
            }
        })
    }
    const removeMagnetFromTemplate = (magnet) => {
        setBoard(prevBoard => {
            return {
                ...prevBoard, rows: prevBoard.rows.map((row, yIndex) => {
                    return {
                        ...row, cells: row.cells.map((cell, xIndex) => {
                            if (xIndex === magnet.coordinates.x && yIndex === magnet.coordinates.y) {
                                PutCell({ ...cell, magnetId: null, magnet: null }, board.id);
                                return { ...cell, magnetId: null, magnet: null };
                            }
                            else
                                return cell
                        })
                    }
                })
            }
        });
    }
    const addMagnetToPouch = (magnet) => {
        const magnetToAdd = {
            id: magnet.data.id,
            magnetName: magnet.data.magnetName,
            magnetColor: magnet.data.magnetColor,
            textColor: magnet.data.textColor
        }
        setMagnets((prevList) => [magnetToAdd, ...prevList,]);
        showNotification({
            title: 'Pouched!',
            message: magnet.data.magnetName + ' was stored in pouch.',
            color: 'cyan',
            autoClose: 2000,
            containerWidth: 10
        });
    }
    const removeMagnetFromPouch = (magnet) => {
        if (!magnet.data.magnetgroup?.canBeCloned)
            setMagnets((prevList) => { return prevList.filter(item => item.id !== magnet.data.id) });
    }

    const saveBoard = async () => {
        let data = await saveTemplate(board);
        if (data.ok) {
            showNotification({
                title: 'Board Default Saved',
                message: 'You have saved a new default for this board!',
                color: 'cyan'
            })
        }
    }

    const loadBoard = async () => {
        showNotification({
            id: 'defaultload',
            title: 'Loading Board\'s Default',
            message: 'Loading Magnets...',
            loading: true,
            color: 'orange'
        })

        try {
            const data = await loadTemplate(board.id);

            if (data) {
                setMagnets(data.magnetPouch);
                setBoard(data.template);
                UpdateCells(data.template);

                updateNotification({
                    id: 'defaultload',
                    title: 'Default Loaded',
                    message: 'The default magnet layout has been loaded',
                    loading: false,
                    color: 'cyan'
                })
            }
        }
        catch (ex) { console.error(ex) }

    }

    const handleBoardReset = async () => {
        showNotification({
            id: 'clearboard',
            title: 'Clearing Board',
            message: 'Removing Magnets',
            color: 'orange',
            loading: true
        })
        await ResetBoard(board.id);
        let boardData = await getActiveBoardWithPouchById(board.id);
        setBoard(boardData.template);
        setMagnets(boardData.magnetPouch);

        UpdateCells(boardData.template);
        updateNotification({
            id: 'clearboard',
            title: 'Board Cleared',
            message: 'All Magnets Removed',
            color: 'cyan',
            loading: false
        })
    }

    const archiveBoard = async () => {
        setStartCount(true);
        setCounter(10);
        setArchiveDisabled(true);

        let boardObj = {
            board: board,
            createdBy: AuthStore.username,
            templateId: board.id
        }
        let data = await newArchiveRecord(boardObj);
        if (data.ok) {
            showNotification({
                title: 'Board Archived ',
                message: new Date().toLocaleString(),
                color: 'cyan',
                loading: false
            })
        }
        //setTimeout(() => { setArchiveDisabled(false) }, 10000);
    }

    useEffect(() => {
        if (startCount) {
            const interval = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 1000);

            return () => {
                if (counter <= 0) {
                    setStartCount(false);
                    setArchiveDisabled(false);
                }
                clearInterval(interval);
            }
        }
    }, [counter]);


    const confirmAction = (title, actionFunction) => openConfirmModal({
        title: (<Center><Title order={5}>{title}</Title></Center>),
        children: (<Text align='center'>Are you sure you wish to continue?</Text>),
        labels: { confirm: 'Yes', cancel: 'Cancel' },
        confirmProps: { variant: 'light' },
        onConfirm: () => actionFunction()
    })

    const handleMagnetCreateSubmit = async () => {
        closeAllModals();
        let boardData = await getActiveBoardWithPouchById(board.id);
        setBoard(boardData.template);
        setMagnets(boardData.magnetPouch);
    }

    const handleCreateMagnet = () => openModal({
        title: (<Center><Title order={5}>Create Magnet</Title></Center>),
        children: (<MagnetForm templateId={board.id} onSubmit={handleMagnetCreateSubmit} />),
        size: 'xl',
        closeOnClickOutside: false,
    })


    //#endregion
    const rowHeight = String(80 / board.rows.length) < 7 ? String(80 / board.rows.length) + 'vh' : '7vh'
    return (
        <>
            {
                true ?
                    <div>
                        <div>
                            <MagnetSideBar magnetGroups={magnetGroups} word={{ wordEntered, setWordEntered }}
                                drawer={{ drawerVisibility, setDrawerVisibility }} openState={{ sideBarOpen, setSideBarOpen }} magnets={magnets} />
                        </div>
                        <div>
                            <PouchComponent openState={{ sideBarOpen, setSideBarOpen }} rowHeight={rowHeight} moveMagnet={moveMagnet} sortMagnetToPouch={sortMagnetToPouch} />
                        </div>
                        <div style={{ paddingTop: '15px', paddingBottom: '60px' }}>
                            <TemplateComponent className="templateSlot" rowHeight={rowHeight} key={board.id} board={board} moveMagnet={moveMagnet} />
                        </div>
                        <Footer height={60} p="md" fixed withBorder>
                            <Group position='apart'>
                                <Group position="left">
                                    <Button.Group buttonBorderWidth={1}>
                                        <Button mr={4} radius='md' disabled={archiveDisabled} onClick={() => archiveBoard()}>{counter > 0 ? counter : 'Archive'}</Button>
                                        <Button mr={4} radius='md' onClick={() => confirmAction('Set Default Board?', saveBoard)}> Set Default </Button>
                                        <Button radius='md' onClick={() => confirmAction('Load Default Board?', loadBoard)}>Load Default</Button>
                                    </Button.Group>
                                    <Button color='red' radius='md' variant='outline' onClick={() => confirmAction('Remove All Magnets?', handleBoardReset)}>Clear Board</Button>

                                </Group>
                                <Group position='right'>
                                    <Button size="xs" color='cyan' compact onClick={() => {
                                        handleCreateMagnet();
                                    }}><SquarePlus size={15} /> New Magnet</Button>
                                    <Button variant='subtle' compact onClick={toggle}>{fullscreen ? (<ScreenNormalIcon />) : (<ScreenFullIcon />)}</Button>
                                </Group>
                            </Group>
                        </Footer>
                    </div>

                    :
                    <div style={{ paddingTop: '15px' }}>
                        <TemplateComponent className="templateSlot" rowHeight={rowHeight} key={board.id} board={board} moveMagnet={moveMagnet} />
                    </div>
            }
        </>
    );
}

export default Board;