import React, { useEffect, useState } from 'react'
import useAuth from '../../contexts/contextHooks/useAuth';
import { LoadingOverlay } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { refresh } from '../../hooks/usePrivateFetch';
import { observer } from 'mobx-react';
import { AuthStore } from '../../stores/authStore';

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const verfiyAuthData = async () => {
      try {
        await refresh();
      }
      catch (err) {
        console.error(err);
      }
      finally {
        isMounted && setIsLoading(false);
      }
    }

    // Avoids unwanted call to verifyRefreshToken
    !AuthStore.accessToken ? verfiyAuthData() : setIsLoading(false);

    return () => isMounted = false;
  }, [])
  return (<>
    <LoadingOverlay visible={isLoading} />
    {!isLoading && <Outlet />}
  </>)
}

export default observer(PersistLogin);