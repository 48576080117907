import { API_URL } from './GlobalFetchSettings.js'
import { privateFetch as fetch } from '../../hooks/usePrivateFetch.js';
import { AuthStore } from '../../stores/authStore.ts';
import { decode } from 'base64-arraybuffer';
const BOARD_URL = API_URL + '/Boards';
const TEMPLATE_URL = API_URL + '/Template';
const MAGNET_URL = API_URL + '/Magnet';
const MAGNETGROUP_URL = API_URL + '/MagnetGroup';

//get request
export const GetMagnets = async () => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthStore.accessToken
            }
        };
        const response = await fetch(MAGNET_URL, requestOptions);
        return response.json();
    }
    catch (e) {
        console.error(e)
    }
};

export const GetMagnetsByGroupId = async (id) => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthStore.accessToken
            }
        };
        const response = await fetch(MAGNET_URL + '/MagnetGroup/' + id, requestOptions);
        return response.json();
    }
    catch (e) {
        console.error(e)
    }
}

export const GetMagnetById = async (id, setData) => {
    fetch(MAGNET_URL + '/' + id)
        .then((res) => res.json()
            .then((data) => {
                setData(data);
            }))
        .catch(ex => console.error(ex));
}

//post request
export async function PostMagnet(magnet) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + AuthStore.accessToken
        },
        body: JSON.stringify(magnet)
    };
    try {
        const res = await fetch(MAGNET_URL, requestOptions)
        return res.json();
    } catch (ex) {
        console.error(ex);
    }
}
export async function PostMagnetFromBoard(magnet, templateId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + AuthStore.accessToken
        },
        body: JSON.stringify(magnet)
    };
    try {
        const res = await fetch(MAGNET_URL + '/boardscope/' + templateId, requestOptions)
        return res.json();
    } catch (ex) {
        console.error(ex);
    }
}

//put request
export async function PutMagnet(magnet) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + AuthStore.accessToken
        },
        body: JSON.stringify(magnet)
    };
    try {
        const res = await fetch(MAGNET_URL + '/' + magnet.id, requestOptions)
        return res.json();
    }

    catch (ex) {
        console.error(ex);
    }
}

//delete requeset
export async function DeleteMagnet(magnetId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + AuthStore.accessToken
        },
    };
    fetch(MAGNET_URL + '/' + magnetId, requestOptions)
        .then((response) => {
            return response;
        });
}
