import { createContext, useState, useEffect, useRef } from "react";
import { deleteArchiveRecord, GetRecords } from '../exports/fetch/ArchiveFetch';
import useAuth from './contextHooks/useAuth';

const ArchiveContext = createContext({});

export const ArchiveProvider = ({ children }) => {
    const [archiveList, setArchiveList] = useState([]);
    const [loading, setLoading] = useState(true);
    const { getToken } = useAuth();
    const refreshInterval = useRef(null);

    const getRecords = async () => {
        const data = await GetRecords();
        setArchiveList(data);
        setLoading(false);
    }

    const destroyRecord = async (id) => {
        await deleteArchiveRecord(id);
        setArchiveList(prevList => prevList.filter(t => {
            return t.id !== id
        }));
    }

    const stopArchiveListRefresh = () => {
        clearInterval(refreshInterval.current);
    }

    const startArchiveListRefresh = () => {
        setLoading(true);
        getRecords();
        refreshInterval.current = setInterval(() => {
            getRecords();
        }, 10000);
    }

    useEffect(() => {
        return () => clearInterval(refreshInterval.current);
    }, []);

    return (
        <>
            <ArchiveContext.Provider value={{ archiveList, setArchiveList, destroyRecord, loading, stopArchiveListRefresh, startArchiveListRefresh }}>
                {children}
            </ArchiveContext.Provider>
        </>
    )
}

export default ArchiveContext; 