import { useEffect } from 'react';
import { MagnetForDisplayOnly } from "../../ViewComponents/MagnetComponent";
import { Container, Title, Badge, LoadingOverlay, Space, Flex } from '@mantine/core';
import { Lock, Stack3 } from 'tabler-icons-react'
import useMagnetGroups from '../../../contexts/useMagnetGroups';
import { openModal, closeAllModals } from '@mantine/modals';
import MagnetGroupForm from './MagnetGroupForm';
import { MagnetProvider } from '../../../contexts/MagnetContext';
import useSettings from '../../../contexts/contextHooks/useSettings';
import { TableSort } from '../../Utilities/TableSort';

const MagnetGroupList = () => {
    const {
        magnetGroupList,
        loading,
        startMagnetGroupListRefresh,
        stopMagnetGroupListRefresh
    } = useMagnetGroups();
    const { fullNavbar } = useSettings();

    useEffect(() => {
        startMagnetGroupListRefresh();
        return () => stopMagnetGroupListRefresh();
    }, [])

    const handleMagnetGroupForm = (group) => openModal({
        children: (<MagnetProvider><MagnetGroupForm groupData={group} onSubmit={closeAllModals} /></MagnetProvider>),
        closeOnClickOutside: false,
        size: '70vw',
    });

    return (
        <>
            {magnetGroupList && (
                <Container pr={{ xl: fullNavbar ? '300px' : null }} style={{ marginTop: '8vh' }} fluid>
                    <LoadingOverlay visible={loading} />
                    <Title align="center" order={3}>MagniGroups</Title>

                    {magnetGroupList && !loading && (
                        <TableSort
                            tableLayout='auto'
                            newButtonText='New MagniGroup'
                            handleClickNew={handleMagnetGroupForm}
                            handleClickItem={(id) => handleMagnetGroupForm(magnetGroupList.filter(mg => String(mg.id) === String(id))[0])}
                            data={magnetGroupList && magnetGroupList.map(mg => {
                                return {
                                    id: String(mg.id),
                                    "MagniGroup": {
                                        value: mg.name,
                                        colWidth: '25%',
                                        view: (<Flex wrap='nowrap' >{mg.name}{(mg.canBeCloned || mg.id === 1) && (<Space w={10} />)}{mg.canBeCloned && (<Stack3 size={15} />)}{mg.id === 1 && (<Lock size={15} />)}</Flex>)
                                    },
                                    "Default Color Scheme": {
                                        value: undefined,
                                        view: (<MagnetForDisplayOnly key={'samplemagnet' + mg.id} defaultText='<Sample>' data={{ magnetColor: mg.defaultMagnetColor, textColor: mg.defaultTextColor }} />)
                                    },
                                    "Boards": {
                                        value: mg.templates?.length,
                                        view: (<Flex wrap='wrap' gap={3}>
                                            {mg.templates?.map((t, i) => {
                                                return (<Badge key={'badge ' + mg.id + '-' + i} size='lg' color='cyan' radius='md' variant='dot'>{t?.templateName}</Badge>);
                                            })}
                                        </Flex>)
                                    }
                                }
                            })}
                        />
                    )}
                </Container>)}
        </>
    )
}
export default MagnetGroupList