import { API_URL, customErrorNotification } from './GlobalFetchSettings.js'
import { privateFetch as fetch } from '../../hooks/usePrivateFetch.js';
import { ArchiveSchedulerTemplate } from '../../Components/AdminConsole/ArchiveLogs/ArchiveSchedulerStore.js';

const ARCHIVE_SCHEDULER_URL = API_URL + '/ArchiveScheduler';

export async function GetArchiveJobs() {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const res = await fetch(ARCHIVE_SCHEDULER_URL, requestOptions);
        return res.json();
    } catch (e) {
        console.error("ERROR: " + e)
    }
}

export async function NewArchiveJob(taskData: ArchiveSchedulerTemplate) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(taskData)
    };
    try {
        let res = await fetch(ARCHIVE_SCHEDULER_URL, requestOptions)
        if (!res.ok) {
            customErrorNotification(res);
            return false;
        }
        return res.json();
    } catch (ex) {
        console.error(ex);
    }
}

export async function DeleteJob(id: number) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    try {
        let res = await fetch(ARCHIVE_SCHEDULER_URL + '/' + id, requestOptions)
        if (res.ok) return true;
        else if (res.status === 404) customErrorNotification(res, 'Could not find Role to delete')
        else customErrorNotification(res);
        return false;
    } catch (ex) {
        console.error(ex);
    }
}